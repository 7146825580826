import { useForm } from "react-hook-form";
import {
  useAuthContext,
  useGoogleSignIn,
  useScreenSize,
  useSignUp,
} from "../../hooks";
import AuthHeader from "./AuthHeader";
import { TextInput } from "../TextInput";
import Button, { ButtonType } from "../Button";
import AuthFooter from "./AuthFooter";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleIcon } from "../../assets/icons/google";
import Checkbox from "../Checkbox";
import { AuthActions } from "./AuthActions";
import { Loader } from "../Loader";
import CloudflareTurnstile from "../Turnstile";
import { useState } from "react";

// Define the PasswordCredential interface if it's not available in the TypeScript types
interface PasswordCredentialData {
  id: string;
  password: string;
  name?: string;
}

// Extend the Window interface to include the Credential Management API
declare global {
  interface Window {
    PasswordCredential?: new (data: PasswordCredentialData) => Credential;
  }
}

const Register = ({
  completionCallback,
  setAuthStrategy,
  modal,
}: {
  completionCallback?: () => void;
  setAuthStrategy: (strategy: string) => void;
  modal: boolean;
}) => {
  const { isMobile } = useScreenSize();
  const { user } = useAuthContext();
  const [turnstileToken, setTurnstileToken] = useState<string>("");
  const [turnstileError, setTurnstileError] = useState<string>("");
  
  const {
    mutate: signUp,
    isPending: isSignupPending,
    error: signupError,
  } = useSignUp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate: googleSignIn, isPending: isGoogleSignInPending } =
    useGoogleSignIn();

  const saveCredentials = (email: string, password: string, name: string, surname: string) => {
    // Check if the Credential Management API is available
    if (window.PasswordCredential) {
      try {
        const cred = new window.PasswordCredential({
          id: email,
          password: password,
          name: `${name} ${surname}`,
        });
        
        // Store the credentials
        navigator.credentials.store(cred)
          .then(() => {
            console.log('Credentials saved successfully');
          })
          .catch(err => {
            console.error('Error saving credentials:', err);
          });
      } catch (error) {
        console.error('Error creating credential object:', error);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleSignIn(codeResponse.access_token);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onSubmit = (data: any) => {
    if (!turnstileToken) {
      setTurnstileError("Please complete the Turnstile verification");
      return;
    }
    
    signUp({ ...data, turnstileToken }, {
      onSuccess: () => {
        saveCredentials(data.email, data.password, data.name, data.surname);
        completionCallback?.();
      },
    });
  };

  if (user) {
    return <div>User already logged in</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="p-10 mmd:p-0 mmd:py-4">
        {isMobile && !modal ? (
          <AuthActions
            selected="register"
            onLogin={() => setAuthStrategy("signin")}
          />
        ) : (
          <AuthHeader
            title="Sign Up"
            subtitle="lets sign up quickly to get started"
          />
        )}
        <div className="flex flex-col mt-6">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-x-[18px]">
              <TextInput
                type="text"
                name="name"
                label="Name"
                placeholder="Name"
                registerInForm={register}
                validation={{ required: "Name is required" }}
                errorMessage={errors?.name?.message as string}
              />
              <TextInput
                type="text"
                name="surname"
                label="Surname"
                registerInForm={register}
                validation={{ required: "Surname is required" }}
                placeholder="Surname"
                errorMessage={errors?.surname?.message as string}
              />
            </div>
            <TextInput
              type="text"
              name="email"
              label="Email"
              placeholder="Email"
              registerInForm={register}
              validation={{
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter a valid email address",
                },
              }}
              errorMessage={errors?.email?.message as string}
            />
            <TextInput
              type="password"
              name="password"
              label="Password"
              registerInForm={register}
              validation={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              }}
              placeholder="Password"
              errorMessage={errors?.password?.message as string}
            />
            <Checkbox
              label="Accept Privacy policy and Terms & Conditions"
              labelClassName="hover:underline"
              onLabelClick={() =>
                window.open(
                  "/privacy-and-terms",
                  "_blank",
                  "noopener,noreferrer",
                )
              }
              registerInForm={register}
              name="terms"
              validation={{
                required: "You must accept the terms and conditions",
              }}
              errorMessage={errors?.terms?.message as string}
            />
            <div className="flex justify-center items-center py-4">
              <CloudflareTurnstile
                onVerify={setTurnstileToken}
                onError={() => setTurnstileError("Turnstile verification failed")}
                onExpire={() => setTurnstileError("Turnstile verification expired")}
              />
            </div>
            {turnstileError && (
              <p className="text-red-500 text-sm">{turnstileError}</p>
            )}
          </div>
          <div className="flex flex-col gap-y-[18px] text-center mb-[24px]">
            <div className="flex justify-center items-center">
              <p className="text-md text-[red]">
                {signupError && signupError?.message}
              </p>
            </div>
            {isSignupPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button type="submit" disabled={isSignupPending}>
                sign up
              </Button>
            )}
            <span className="text-[#4F575E]">or</span>
            {isGoogleSignInPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button
                type="button"
                category={ButtonType.SECONDARY}
                className="capitalize flex justify-center items-center gap-x-[10px] !py-2"
                onClick={login}
              >
                <GoogleIcon /> Sign up with google
              </Button>
            )}
          </div>
          <AuthFooter
            actionText="Already have an account?"
            linkText="sign in"
            setAuthStrategy={() => setAuthStrategy?.("signin")}
          />
        </div>
      </div>
    </form>
  );
};

export default Register;
