import CheckBadgeIcon from "../../assets/icons/checkBadge.jsx";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Task, TaskStatus } from "../../hooks/useCommunity";
import {
  useCheckAllTasks,
  useStartTask,
} from "../../hooks/useTask/useUserTask";
import RestrictedComponent from "../../pages/utils/RestrictedComponent";
import Button, { ButtonType } from "../Button";
import { useEffect, useState } from "react";
import DrawerIcon from "../../assets/icons/drawer";
import { Loader } from "../Loader";
import { Confetti } from "../Confetti";
import { TwitterConnectModal } from "./TwitterConnectModal";
import moment from "moment";
import MissionCompletionModal from "./MissionCompletionModal";
import { taskTypes } from "../../constants";
import UserInfoInputModal from "./UserInfoInputModal";
import { TelegramConnectModal } from "./TelegramConnectModal";
import ScreenUploadModal from "./ScreenUploadModal";
import { useUploadTaskScreenshot } from "../../hooks/useTask/useUserTask";

const absoluteLink = (link: string) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

const MissionPageTasks = ({
  tasks,
  _id: missionId,
  lastCheckTime,
  name,
  imageUrl,
}: {
  tasks: Task[];
  lastCheckTime?: Date;
  _id: string;
  name: string;
  imageUrl: string;
}) => {
  const { user } = useAuthContext();
  const [showConfetti, setShowConfetti] = useState(false);
  const { mutate: startTask, isPending: isStartPending } = useStartTask();
  const { mutate: checkAllTasks, isPending: isCheckPending } =
    useCheckAllTasks();
  const [lastClicked, setLastClicked] = useState<string | undefined>();
  const [twitterConnectModal, setTwitterConnectModal] =
    useState<boolean>(false);
  const [telegramConnectModal, setTelegramConnectModal] =
    useState<boolean>(false);
  const [checkDisabled, setCheckDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [missionCompletionModal, setMissionCompletionModal] =
    useState<boolean>(false);
  const [userInputModal, setUserInputModal] = useState<boolean>(false);
  const [userInputTask, setUserInputTask] = useState<Task | null>(null);
  const [screenUploadModal, setScreenUploadModal] = useState<boolean>(false);
  const [currentUploadTask, setCurrentUploadTask] = useState<Task | null>(null);
  const { mutate: uploadTaskScreenshot, isPending: isUploadPending } =
    useUploadTaskScreenshot();
  const [missingScreenshots, setMissingScreenshots] = useState(false);
  const [hasUnstartedTasks, setHasUnstartedTasks] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (showConfetti) {
      timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showConfetti]);

  useEffect(() => {
    if (tasks.every((task) => task.status === TaskStatus.COMPLETED)) {
      setCheckDisabled(true);
      setTimeLeft(0);
      return;
    }

    const interval = setInterval(() => {
      if (!lastCheckTime) return;
      const now = moment.utc().toDate();
      const diff =
        (now.getTime() - moment.utc(lastCheckTime).toDate().getTime()) / 1000;
      const remaining = 300 - diff; // 5 minutes countdown
      if (remaining > 0) {
        setTimeLeft(Math.ceil(remaining));
        setCheckDisabled(true);
      } else {
        setTimeLeft(0);
        setCheckDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [lastCheckTime, tasks]);

  useEffect(() => {
    if (
      timeLeft === 0 &&
      tasks.some(
        (task) =>
          task.status === TaskStatus.IN_PROGRESS ||
          task.status === TaskStatus.VERIFY_IN_PROGRESS ||
          task.status === TaskStatus.FAILED
      )
    ) {
      setCheckDisabled(false);
    }
  }, [tasks, timeLeft]);

  // Add a new useEffect to check if all tasks requiring screenshot proofs have been provided
  useEffect(() => {
    // Find all tasks that need screenshot proofs
    const tasksNeedingProof = tasks.filter(
      (task) =>
        task.proofNeededTask &&
        (task.status === TaskStatus.IN_PROGRESS ||
          task.status === TaskStatus.VERIFY_IN_PROGRESS)
    );

    // If there are tasks needing proof
    if (tasksNeedingProof.length > 0) {
      // Check if any of them are missing screenshots
      const allProofsProvided = tasksNeedingProof.every(
        (task) => task.screenshotImageUrl
      );

      // Check if all other tasks have been started (are in progress or completed)
      const allOtherTasksStarted = tasks.every(
        (task) =>
          task.proofNeededTask || // Skip proof tasks as they're handled separately
          task.status === TaskStatus.COMPLETED ||
          task.status === TaskStatus.IN_PROGRESS ||
          task.status === TaskStatus.VERIFY_IN_PROGRESS
      );

      // If timeLeft is 0 and either proofs are missing or other tasks haven't been started, disable the submit button
      if (timeLeft === 0 && (!allProofsProvided || !allOtherTasksStarted)) {
        setCheckDisabled(true);
        setMissingScreenshots(!allProofsProvided);
        setHasUnstartedTasks(!allOtherTasksStarted);
      } else {
        setMissingScreenshots(false);
        setHasUnstartedTasks(false);
      }
    } else {
      setMissingScreenshots(false);
      setHasUnstartedTasks(false);
    }
  }, [tasks, timeLeft]);

  const checkTasks = () => {
    const hasConnection = tasks.some((task) => {
      const taskType = taskTypes.find((t) => t.value === task.type);
      return taskType?.connection;
    });
    const isTelegramConnection = tasks.some((task) => {
      const taskType = taskTypes.find((t) => t.value === task.type);
      return taskType?.telegramConnection;
    });
    if (!user?.isTwitterConnected && hasConnection)
      return setTwitterConnectModal(true);
    if (!user?.isTelegramConnected && isTelegramConnection)
      return setTelegramConnectModal(true);

    checkAllTasks(
      { missionId },
      {
        onSuccess: (data) => {
          if (data?.allTasksCompleted) {
            setMissionCompletionModal(true);
            setShowConfetti(true);
            setCheckDisabled(true);
          }
        },
      }
    );
  };

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="w-full flex flex-col p-6 gap-6 border border-solid border-surface-250 rounded-xl mmd:p-4">
      {twitterConnectModal && (
        <TwitterConnectModal onClose={() => setTwitterConnectModal(false)} />
      )}
      {telegramConnectModal && user?._id && (
        <TelegramConnectModal
          userId={user?._id}
          onClose={() => setTelegramConnectModal(false)}
        />
      )}
      {userInputModal && (
        <UserInfoInputModal
          task={userInputTask}
          onClose={() => setUserInputModal(false)}
          onSubmit={({ userInfo }) => {
            startTask({ ...userInputTask, userInfo });
          }}
        />
      )}
      <p className="body-xl-medium text-surface-900">Task</p>
      <div className="flex flex-col gap-2">
        {tasks?.map((task: Task, index: number) => (
          <div
            className={`flex rounded-lg justify-between border border-solid p-4 gap-6 cursor-pointer border-surface-250 hover:border-primary-300 items-center ${
              task.status === TaskStatus.COMPLETED && "bg-primary-50"
            } ${
              task.proofNeededTask &&
              (task.status === TaskStatus.IN_PROGRESS ||
                task.status === TaskStatus.VERIFY_IN_PROGRESS) &&
              !task.screenshotImageUrl &&
              "border-red-400 border-2"
            } ${
              !task.proofNeededTask &&
              task.status !== TaskStatus.COMPLETED &&
              task.status !== TaskStatus.IN_PROGRESS &&
              task.status !== TaskStatus.VERIFY_IN_PROGRESS &&
              tasks.some((t) => t.proofNeededTask) &&
              "border-yellow-400 border-2"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (!user) return;
              if (
                task.type === "Share name and email address" ||
                task.type === "Request User Info"
              )
                return;
              console.log(task.type, "opening");
              window.open(
                absoluteLink(
                  task.type === "X tweet"
                    ? `https://x.com/intent/post?text=${task.link}`
                    : task.link
                ),
                "_blank",
                "noopener,noreferrer"
              );
            }}
            key={task?._id ?? index}
          >
            <div className="flex gap-3 items-center">
              <div className="w-11 h-11 rounded-lg bg-[#F4E8A3] flex items-center justify-center p-2">
                <DrawerIcon />
              </div>
              <div className="flex flex-col w-full mmd:max-w-[80px]">
                {task.type === "Share name and email address" ? (
                  <>
                    <p className="body-lg-bold text-surface-900">{task.link}</p>
                    <p className="body-sm text-surface-700 truncate break-words">
                      {task.type}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="body-lg-bold text-surface-900">{task.type}</p>
                    <p className="body-sm text-surface-700 truncate break-words">
                      {task.customMessage}: {task.link}
                    </p>
                  </>
                )}
              </div>
            </div>

            {!user ? (
              <RestrictedComponent>
                <Button
                  className="h-full"
                  action={(e) => {
                    e.stopPropagation();
                    if (task.type === "Request User Info") {
                      setUserInputTask(task);
                      setUserInputModal(true);
                    } else {
                      startTask({ ...task });
                    }
                  }}
                >
                  {task.type === "Share name and email address"
                    ? "Agree"
                    : "Start"}
                </Button>
              </RestrictedComponent>
            ) : (isCheckPending || isStartPending) &&
              task._id === lastClicked ? (
              <div className="flex h-full items-center">
                <Loader size="md" />
              </div>
            ) : task.status === TaskStatus.COMPLETED ? (
              <CheckBadgeIcon />
            ) : task.status === TaskStatus.IN_PROGRESS ||
              task.status === TaskStatus.VERIFY_IN_PROGRESS ? (
              <div className="flex h-full items-center">
                {task.status === TaskStatus.VERIFY_IN_PROGRESS ? (
                  <Button
                    className="h-full bg-gray-400 cursor-not-allowed text-white"
                    category={ButtonType.CUSTOM}
                    disabled
                  >
                    Verifying
                  </Button>
                ) : task.proofNeededTask ? (
                  <div className="flex flex-col">
                    <Button
                      className="h-full"
                      action={(e) => {
                        e.stopPropagation();
                        setCurrentUploadTask(task);
                        setScreenUploadModal(true);
                      }}
                    >
                      {task.screenshotImageUrl
                        ? "Edit Screenshot"
                        : "Upload Screen"}
                    </Button>
                    {!task.screenshotImageUrl && (
                      <span className="text-xs text-red-500 mt-1">
                        Screenshot required
                      </span>
                    )}
                  </div>
                ) : (
                  <Button
                    className="h-full bg-gray-400 cursor-not-allowed text-white"
                    category={ButtonType.CUSTOM}
                    disabled
                  >
                    {task.type === "Share name and email address"
                      ? "Agreed"
                      : "Started"}
                  </Button>
                )}
              </div>
            ) : task.status === TaskStatus.FAILED ? (
              <div>Try Again</div>
            ) : (
              <Button
                className="h-full"
                action={(e) => {
                  e.stopPropagation();
                  setLastClicked(task._id);
                  if (task.type === "Request User Info") {
                    setUserInputTask(task);
                    setUserInputModal(true);
                  } else {
                    startTask({ ...task });
                  }
                }}
              >
                {task.type === "Share name and email address"
                  ? "Agree"
                  : "Start"}
              </Button>
            )}
          </div>
        ))}
        <div className="flex gap-2 items-center">
          {isCheckPending ? (
            <Loader size="md" />
          ) : (
            <div className="relative group">
              <Button
                className={`bg-[#FF9100] hover:bg-[#FF9F00] text-white ${checkDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                action={(e) => {
                  e.stopPropagation();
                  if (!checkDisabled) {
                    checkTasks();
                  }
                }}
                category={ButtonType.CUSTOM}
                disabled={checkDisabled}
                title={
                  missingScreenshots
                    ? "You must upload screenshots for all required tasks before submitting"
                    : timeLeft > 0
                      ? "Please wait before checking again"
                      : hasUnstartedTasks
                        ? "All tasks must be started before submitting"
                        : "Submit your completed tasks"
                }
              >
                {tasks.some((task) => task.proofNeededTask)
                  ? "Submit"
                  : "Check All"}
              </Button>

              {checkDisabled &&
                !timeLeft &&
                (hasUnstartedTasks || missingScreenshots) && (
                  <div className="absolute hidden group-hover:block z-10 mt-2 w-64 p-2 bg-black bg-opacity-80 text-white text-xs rounded whitespace-normal">
                    {missingScreenshots && (
                      <p className="mb-1">
                        • Tasks with red borders need screenshots uploaded.
                      </p>
                    )}
                    {hasUnstartedTasks && (
                      <p className="mb-1">
                        • Tasks with yellow borders need to be started.
                      </p>
                    )}
                    <p>
                      You must complete these requirements before submitting.
                    </p>
                  </div>
                )}
            </div>
          )}
          {timeLeft > 0 && (
            <div className="text-center">{formatTimeLeft()}</div>
          )}
        </div>
      </div>
      {missionCompletionModal && (
        <>
          <MissionCompletionModal
            name={name}
            imageUrl={imageUrl}
            userName={user?.name}
            onClose={() => setMissionCompletionModal(false)}
          />
        </>
      )}

      {showConfetti && (
        <div className="fixed inset-0 z-[1000]">
          <Confetti />
        </div>
      )}

      {screenUploadModal && currentUploadTask && (
        <ScreenUploadModal
          task={currentUploadTask}
          onClose={() => setScreenUploadModal(false)}
          onSubmit={(proof) => {
            uploadTaskScreenshot(
              {
                missionId,
                taskId: currentUploadTask._id || "",
                proof,
              },
              {
                onSuccess: () => {
                  setScreenUploadModal(false);
                  // Optionally refresh task data here if needed
                },
              }
            );
          }}
          existingScreenshot={currentUploadTask.screenshotImageUrl}
        />
      )}
    </div>
  );
};

export default MissionPageTasks;
