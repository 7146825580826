import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useAuthContext } from "../../hooks";

interface DateRangeInfoProps {
  validFrom?: string;
  validTo?: string;
}

interface ITimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const DateRangeInfo = ({ validFrom, validTo }: DateRangeInfoProps) => {
  const { user } = useAuthContext();
  const [fromTimeLeft, setFromTimeLeft] = useState<ITimeLeft>({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [toTimeLeft, setToTimeLeft] = useState<ITimeLeft>({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const timezone = user?.timezone?.value ?? moment.tz.guess();

    const calculateTimeLeft = () => {
      if (validFrom) {
        const now = moment().tz(timezone);
        const startDate = moment(validFrom).tz(timezone);
        const duration = moment.duration(startDate.diff(now));

        if (duration.asSeconds() > 0) {
          setFromTimeLeft({
            days: String(Math.floor(duration.asDays())).padStart(2, "0"),
            hours: String(duration.hours()).padStart(2, "0"),
            minutes: String(duration.minutes()).padStart(2, "0"),
            seconds: String(duration.seconds()).padStart(2, "0"),
          });
        } else {
          setFromTimeLeft({
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
          });
        }
      }

      if (validTo) {
        const now = moment().tz(timezone);
        const endDate = moment(validTo).tz(timezone);
        const duration = moment.duration(endDate.diff(now));

        if (duration.asSeconds() > 0) {
          setToTimeLeft({
            days: String(Math.floor(duration.asDays())).padStart(2, "0"),
            hours: String(duration.hours()).padStart(2, "0"),
            minutes: String(duration.minutes()).padStart(2, "0"),
            seconds: String(duration.seconds()).padStart(2, "0"),
          });
        } else {
          setToTimeLeft({
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
          });
        }
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [user?.timezone?.value, validFrom, validTo]);

  const hideFromTimeLeft =
    fromTimeLeft.days === "00" &&
    fromTimeLeft.hours === "00" &&
    fromTimeLeft.minutes === "00" &&
    fromTimeLeft.seconds === "00";

  const hideToTimeLeft =
    toTimeLeft.days === "00" &&
    toTimeLeft.hours === "00" &&
    toTimeLeft.minutes === "00" &&
    toTimeLeft.seconds === "00";

  if (!validFrom && !validTo) {
    return null;
  }

  return (
    <div className="flex flex-row justify-around items-center gap-10 p-6 border border-dashed rounded-lg mmd:flex-col">
      {validFrom ? (
        <div className="flex flex-col items-center">
          <h2 className="body-lg mb-2">Valid From</h2>
          <span className="body-md font-bold">
            {validFrom ? moment(validFrom).format("MMM D, YYYY") : ""}{" "}
            <span className=" font-regular">at</span>{" "}
            {validFrom ? moment(validFrom).format("h:mmA") : ""} UTC
          </span>

          {!hideFromTimeLeft ? (
            <div className="flex gap-2 mt-2">
              <TimeLeft value={fromTimeLeft.days} label="D" />
              <TimeLeft value={fromTimeLeft.hours} label="H" />
              <TimeLeft value={fromTimeLeft.minutes} label="M" />
              <TimeLeft value={fromTimeLeft.seconds} label="S" />
            </div>
          ) : null}
        </div>
      ) : null}

      {validTo ? (
        <div className="flex flex-col items-center">
          <h2 className="body-lg mb-2">Valid Until</h2>
          <span className="body-md font-bold">
            {validTo ? moment(validTo).format("MMM D, YYYY") : ""}{" "}
            <span className=" font-regular">at</span>{" "}
            {validTo ? moment(validTo).format("h:mmA") : ""} UTC
          </span>

          {!hideToTimeLeft ? (
            <div className="flex gap-2 mt-2">
              <TimeLeft value={toTimeLeft.days} label="D" />
              <TimeLeft value={toTimeLeft.hours} label="H" />
              <TimeLeft value={toTimeLeft.minutes} label="M" />
              <TimeLeft value={toTimeLeft.seconds} label="S" />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const TimeLeft = ({ value, label }: { value: string; label: string }) => {
  return (
    <div className="py-2 px-3 border border-surface-[rgba(235, 235, 235, 1)] flex flex-col items-center justify-center bg-gray-50 rounded-md">
      <span className="body-lg font-bold">{value}</span>
      <span className="text-xs text-gray-400">{label}</span>
    </div>
  );
};