
import { TailSpin } from "react-loader-spinner";
import Button from "../Button";
import BoonModal from "../Modal";

const ArchiveMissionModal = ({
  onClose,
  onSubmit,
  isPending,
  missionName,
  archived,
}: {
  onClose: () => void;
  onSubmit: () => void;
  isPending: boolean;
  missionName: string
  archived: boolean;
}) => {
  return (
    <BoonModal onClose={onClose}>
      <div className="max-w-sm m-auto flex flex-col gap-8">
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-title-xl font-bold">Warning</h3>
          <p className="text-body-lg text-[#4F575E]">
            Are you sure you want to {archived ? "unarchive" : "archive"} <span className="font-bold">"{missionName}"</span> ?
          </p>
        </div>
        <div className="flex flex-col w-full gap-4">
          {isPending ? (
            <TailSpin width={40} wrapperStyle={{ margin: "auto" }} />
          ) : (
            <>
              <Button action={onSubmit} className="!bg-[#C92929] py-2">
                Yes, {archived ? "Unarchive" : "Archive"}
              </Button>
              <Button
                action={onClose}
                className="!bg-[#FFFFFF] py-2 !text-black border border-solid border-[#EBEBEB]"
              >
                cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </BoonModal>
  );
};

export default ArchiveMissionModal;