import { useNavigate } from "react-router-dom";

export const Community = ({
  id,
  community,
}: {
  id: string;
  community: any;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className=" w-full overflow-hidden text-wrap border-surface-200 border-solid border rounded-3xl bg-surface-50 pb-8 cursor-pointer hover:shadow-md transition-all duration-300"
      onClick={() =>
        navigate(community.url ? `/${community.url}` : `/community/${id}`)
      }
    >
      <div className="w-full relative flex justify-center">
        <div className="absolute w-full h-full bg-gradient-to-t from-black/10 from-0%" />
        <img
          src={community.coverImageUrl}
          className="rounded-md w-full aspect-[3/1] rounded-t-[10px] object-contain bg-white"
          alt="default_bg"
        />
        <img
          src={community.logoUrl}
          className="w-18 h-18 rounded-2xl object-cover border-4 shadow-md border-solid border-surface-50 absolute translate-y-17 left-4"
          alt="avatar"
        />
      </div>
      <div className="px-4 pt-12 w-full overflow-hidden">
        <div className="flex flex-col gap-3 w-full">
          <h3 className="title-xl-bold break-words">{community.name}</h3>
          <p className="body-lg break-words w-full line-clamp-3 text-surface-500 font-space-grotesk">
            {community.description}
          </p>
        </div>
      </div>
    </div>
  );
};
