import { useState } from "react";
import CloseIcon from "../../assets/icons/close";
import Button from "../Button";
import BoonModal from "../Modal";
import { Task } from "../../hooks/useCommunity";
import UploadFile from "../UploadFile";
import { Loader } from "../Loader";
import { useForm } from "react-hook-form";

interface IScreenUploadModal {
  onClose: () => void;
  task: Task;
  onSubmit: (proof: any) => void;
  existingScreenshot?: string;
}

const ScreenUploadModal = ({
  onClose,
  task,
  onSubmit,
  existingScreenshot,
}: IScreenUploadModal) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      proofScreenshot: existingScreenshot || "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = (data: any) => {
    setIsSubmitting(true);
    onSubmit(data.proofScreenshot);
  };

  const isEditing = !!existingScreenshot;

  return (
    <BoonModal onClose={onClose} style={{ padding: "0px" }}>
      <div className="flex flex-col p-6 gap-4 relative">
        <CloseIcon
          className="absolute top-6 right-6 cursor-pointer"
          onClick={onClose}
        />
        <p className="title-lg-medium text-surface-900">
          {isEditing ? "Update Proof Screenshot" : "Upload Proof Screenshot"}
        </p>
        <p className="body-md text-surface-700">
          Please {isEditing ? "update your" : "upload a"} screenshot showing
          that you've completed this task: {task.type}
        </p>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex flex-col gap-8 max-h-[800px] pb-16 overflow-auto">
            {existingScreenshot && (
              <div className="flex flex-col gap-2">
                <p className="body-md-medium text-surface-700">
                  Current Screenshot:
                </p>
                <img
                  src={existingScreenshot}
                  alt="Current proof"
                  className="max-w-full h-auto rounded-lg"
                  style={{ maxHeight: "200px" }}
                />
              </div>
            )}

            <UploadFile
              setValue={(name, value) => setValue("proofScreenshot", value)}
              errorMessage={errors?.proofScreenshot?.message as string}
              control={control}
              label={isEditing ? "New Screenshot" : "Proof Screenshot"}
              id="proof-screenshot"
              name="proofScreenshot"
              description="PNG, JPG (max. 5MB)"
              validation={{
                required: "Screenshot is required",
              }}
              defaultValue={existingScreenshot}
              enableCropping={false}
            />

            <div className="fixed bottom-0 left-0 w-full py-4 px-6">
              {isSubmitting ? (
                <div className="flex justify-center items-center">
                  <Loader size="md" />
                </div>
              ) : (
                <Button className="px-5 py-3 w-full" type="submit">
                  {isEditing ? "Update Proof" : "Submit Proof"}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </BoonModal>
  );
};

export default ScreenUploadModal;
