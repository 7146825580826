import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/icons/close";
import Button from "../../Button";
import BoonModal from "../../Modal";
import { TextInput } from "../../TextInput";
import { SingleDropDown } from "../../Dropdown";
import { useEffect, useState } from "react";
import { rewardTypes, rewardFulfilmentOptions } from "../../../constants";
import { Reward } from "../../../hooks/useCommunity";
import { useCreateReward, useUpdateReward } from "../../../hooks/useReward";
import UploadFile from "../../UploadFile";
import QuestionMarkIcon from "../../../assets/icons/questionMark";
import { ToggleSwitch } from "../../ToggleSwitch";
import { TextAreaInput } from "../../TextAreaInput";
import DateInput from "../../DateInput";
import CalendarIcon from "../../../assets/icons/calendar";
import BoonTooltip from "../../Tooltip";
import Toast from "../../Toast";
import { Loader } from "../../Loader";
import moment from "moment";

interface IRewardModal {
  onClose: () => void;
  reward?: Reward;
  missionId?: string;
  modalType?: string;
}

const RewardModal = ({
  onClose,
  reward,
  missionId,
  modalType,
}: IRewardModal) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    unregister,
    reset
  } = useForm();
  const {
    mutate: createReward,
    isPending: isCreateRewardPending,
    isError: isCreateRewardError,
    error: createRewardError,
    isSuccess: isCreateRewardSuccess,
  } = useCreateReward();

  const {
    mutate: updateReward,
    isPending: isUpdateRewardPending,
    isError: isUpdateRewardError,
    error: updateRewardError,
    isSuccess: isUpdateRewardSuccess,
  } = useUpdateReward();

  const [type, setType] = useState<string | undefined>();
  const [fulfilmentOption, setFulfilmentOption] = useState<
    string | undefined
  >();
  const [hasRedeemLimit, setHasRedeemLimit] = useState<boolean | undefined>(
    false,
  );
  const [hasDateLimit, setHasDateLimit] = useState<boolean | undefined>(false);
  const [hasValidFrom, setHasValidFrom] = useState<boolean | undefined>(false);
  const [hasValidUntil, setHasValidUntil] = useState<boolean | undefined>(
    false,
  );
  const [hasUniqueCouponCode, setHasUniqueCouponCode] = useState<
    boolean | undefined
  >(false);
  const [hasCouponeCodes, setHasCouponeCodes] = useState<boolean | undefined>(
    false,
  );
  const [couponCodes, setCouponCodes] = useState<string[]>([]);
  const [redeemLimit, setRedeemLimit] = useState<number>(0);

  const onSubmit = (data: any) => {
    const payload = { ...data, type, missionId, couponCodes };
    if (!hasRedeemLimit) {
      delete payload.totalRedeemLimit;
    }
    if (!hasValidFrom) {
      delete payload.validityPeriodStart;
    }
    if (!hasValidUntil) {
      delete payload.validUntil;
    }
    if (fulfilmentOption !== "3rd party" || type !== "Offline Gift") {
      delete payload.couponCodes;
    }
    if (!hasDateLimit) {
      delete payload.validityPeriodStart;
      delete payload.validityPeriodEnd;
    }
    if (type === "Offline Gift") {
      payload.fulfilmentOption = fulfilmentOption;
    }
    if (modalType === "updateReward") {
      updateReward({ _id: reward?._id, payload });
    } else {
      createReward({ missionId, payload });
    }
  };

  const handleCouponUpdate = (value: string, index: number) => {
    const tmp = [...couponCodes];
    tmp[index] = value;
    setCouponCodes(tmp);
  };

  useEffect(() => {
    if (modalType === 'updateReward' && reward) {
      reset(reward);
    } else {
      reset({}); 
    }
  }, [reward, modalType, reset]);

  useEffect(() => {
    if (!Number.isNaN(redeemLimit) && hasUniqueCouponCode) {
      const currenctCouponNumber = couponCodes.length;
      if (redeemLimit > currenctCouponNumber) {
        setCouponCodes([
          ...couponCodes,
          ...new Array(redeemLimit - currenctCouponNumber).fill(""),
        ]);
      } else {
        setCouponCodes(couponCodes.slice(0, redeemLimit));
      }
    }
  }, [redeemLimit]);

  useEffect(() => {
    const currenctCouponNumber = couponCodes?.length;
    if (
      hasUniqueCouponCode &&
      couponCodes &&
      !Number.isNaN(redeemLimit) &&
      redeemLimit
    ) {
      setCouponCodes(
        currenctCouponNumber
          ? [...couponCodes, ...new Array(redeemLimit - 1).fill("")]
          : new Array(redeemLimit).fill(""),
      );
    } else {
      if (currenctCouponNumber) {
        setCouponCodes([couponCodes[0]]);
      } else {
        setCouponCodes([" "]);
      }
    }
  }, [hasUniqueCouponCode]);

  useEffect(() => {
    if (isCreateRewardSuccess || isUpdateRewardSuccess) {
      onClose();
      reset({})
    }
  }, [isCreateRewardSuccess, isUpdateRewardSuccess]);


  return (
    <BoonModal onClose={onClose} style={{ padding: "0px" }}>
      <div className="flex flex-col p-6 gap-4 relative">
        <CloseIcon
          className="absolute top-6 right-6 cursor-pointer"
          onClick={onClose}
        />
        <p className="title-lg-medium text-surface-900">Create Reward</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`flex flex-col gap-8 max-h-[800px] pb-16 ${!type || (type === "Offline Gift" && !fulfilmentOption) ? "overflow-visible" : "overflow-auto"}`}
          >
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <p className="body-lg text-surface-900">Choose Reward Type</p>
                <div className="w-1/3 z-10000">
                  <SingleDropDown
                    placeholder="Reward type"
                    data={rewardTypes}
                    name={`type`}
                    setStateValue={(e: string) => setType(e)}
                    value={
                      modalType === "updateReward" ? reward?.type : undefined
                    }
                  />
                </div>
              </div>
              {type && (
                <>
                  {type === "Offline Gift" && (
                    <>
                      <div className="flex justify-between">
                        <div className="flex gap-2 items-center h-fit">
                          <p className="body-lg text-surface-900">
                            Fulfilment option
                          </p>
                          <a id="fulfilment-option">
                            <QuestionMarkIcon />
                          </a>
                          <BoonTooltip
                            id="fulfilment-option"
                            content="• 1st party fulfillment means we will collect addresses of users for you to send the offline gift to and you must send them yourself.<br />
                          • 3rd party means you will provide details on how they can receive the gift from a 3rd party such as Shopify. You may provide them with a 100% coupon on some merchandise and they will then enter their details on that third party solution.<br />
                          • In-venue means users will have to come to a certain place at a certain time to redeem the gift. e.g. Attend a gig where they will get a free beer when they show themselves redeeming the reward.<br />
                          "
                          />
                        </div>
                        <div className="w-1/3">
                          <SingleDropDown
                            placeholder="Fulfilment option"
                            data={rewardFulfilmentOptions}
                            name={`fulfilmentOption`}
                            setStateValue={(e: string) =>
                              setFulfilmentOption(e)
                            }
                            value={
                              modalType === "updateReward"
                                ? reward?.fulfilmentOption
                                : fulfilmentOption
                            }
                          />
                        </div>
                      </div>
                      {fulfilmentOption === "1st party" && (
                        <Toast content="We will ask users to enter their addresses when they redeem the reward which will be sent to your email and it is your responsibility to deliver the gift to their address" />
                      )}
                    </>
                  )}
                  {(type !== "Offline Gift" || fulfilmentOption) && (
                    <>
                      <TextInput
                        name="name"
                        errorMessage={errors?.name?.message as string}
                        registerInForm={register}
                        placeholder="Collector's editor album art"
                        label="Reward name"
                        validation={{ required: "Name is required" }}
                        labelClassName="body-lg text-surface-900"
                      />
                      <UploadFile
                        setValue={setValue}
                        errorMessage={errors?.rewardImage?.message as string}
                        control={control}
                        label="Reward Image"
                        id="reward-image"
                        emojiable
                        tooltipContent="Reward Image info coming sooon"
                        name={"rewardImage"}
                        description="SVG, PNG, (max. 1080 x 1080E)"
                        isSquare={true}
                      />
                      {type === "Digital Collectible" && (
                        <UploadFile
                          enableCropping={false}
                          setValue={setValue}
                          errorMessage={errors?.fileLink?.message as string}
                          control={control}
                          label="Collectible file"
                          id="collectible-file"
                          tooltipContent="Upload 1 file which will be encoded into an NFT Collectible that the user will receive in their wallet when they redeem after mission completion"
                          name={"fileLink"}
                          description="SVG, PNG, (max. 800x400px)"
                          validation={{
                            required: "Collectible file is required",
                          }}
                        />
                      )}
                      {type !== "Digital Collectible" && (
                        <>
                          <div className="flex justify-between">
                            <p className="body-lg text-surface-900">
                              Limit redemption by date & time?
                            </p>
                            <ToggleSwitch
                              name="hasDateLimit"
                              setValue={(name, e) => {
                                setValue(name, e);
                                setHasDateLimit(e);
                              }}
                            />
                          </div>
                          {hasDateLimit && (
                            <>
                              <div className="flex justify-between items-center">
                                <div className="flex w-full gap-4">
                                  <div className="flex gap-1 items-center">
                                    <p className="body-lg text-surface-900">
                                      Valid From
                                    </p>
                                  </div>
                                  <ToggleSwitch
                                    name="hasValidFrom"
                                    setValue={(name, e) => {
                                      setValue(name, e);
                                      setHasValidFrom(e);
                                    }}
                                    defaultValue={true}
                                  />
                                </div>
                                <div className="flex items-center border border-surface-250 rounded-lg pl-3 w-full">
                                  <CalendarIcon />
                                  <div className="flex items-center">
                                    <DateInput
                                      name="validityPeriodStart"
                                      placeholder="Start Date"
                                      onChange={(data) => {
                                        const utcDate = moment(data)
                                          .utc()
                                          .format();

                                        setValue(
                                          "validityPeriodStart",
                                          utcDate,
                                        );
                                      }}
                                      startFrom="right"
                                      disabled={!hasValidFrom}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="flex w-full gap-4">
                                  <div className="flex gap-1 items-center">
                                    <p className="body-lg text-surface-900">
                                      Valid Until
                                    </p>
                                  </div>
                                  <ToggleSwitch
                                    name="hasValidUntil"
                                    setValue={(name, e) => {
                                      setValue(name, e);
                                      setHasValidUntil(e);
                                    }}
                                    defaultValue={true}
                                  />
                                </div>
                                <div className="flex items-center border border-surface-250 rounded-lg pl-3 w-full">
                                  <CalendarIcon />
                                  <div className="flex items-center">
                                    <DateInput
                                      name="validityPeriodEnd"
                                      placeholder="End Date"
                                      onChange={(data) => {
                                        const utcDate = moment(data)
                                          .utc()
                                          .format();
                                        setValue("validityPeriodEnd", utcDate);
                                      }}
                                      startFrom="right"
                                      disabled={!hasValidUntil}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <TextAreaInput
                        registerInForm={register}
                        name={"successMessage"}
                        label="Success Message"
                        labelClassName="body-lg text-surface-900"
                        tooltipContent="Success Message info coming soon"
                        placeholder="e.g. Well done, thank you so much for supporting me!"
                        id="successMessage"
                        errorMessage={errors?.successMessage?.message as string}
                        validation={{
                          required: "Success Message is required",
                        }}
                      />
                      {type !== "Digital Collectible" && (
                        <TextAreaInput
                          registerInForm={register}
                          name={"redemptionInstructions"}
                          label="Redemption Instructions"
                          labelClassName="body-lg text-surface-900"
                          tooltipContent="These instructions will be shown to user's when they complete the mission and it will also be sent to their email"
                          placeholder="These instructions will be shown to user's when they complete the mission and it will also be sent to their email"
                          id="redemptionInstructions"
                          errorMessage={
                            errors?.redemptionInstructions?.message as string
                          }
                          validation={{
                            required: "Instructions are required",
                          }}
                        />
                      )}
                      {type === "Offline Gift" &&
                        fulfilmentOption === "3rd party" && (
                          <TextInput
                            registerInForm={register}
                            name={"thirdPartyRedemptionUrl"}
                            label="3rd party redemption URL"
                            labelClassName="body-lg text-surface-900"
                            placeholder="e.g. enter the Shopify link for where your gift is"
                            id="thirdPartyRedemptionUrl"
                            errorMessage={
                              errors?.thirdPartyRedemptionUrl?.message as string
                            }
                            validation={{
                              required: "Instructions are required",
                            }}
                          />
                        )}
                      <div className="flex justify-between items-center">
                        <div className="flex w-full gap-4">
                          <div className="flex gap-1 items-center">
                            <p className="body-lg text-surface-900">
                              Total Redeem limit
                            </p>
                            <a id="redeem-limit">
                              <QuestionMarkIcon />
                            </a>
                            <BoonTooltip
                              id="redeem-limit"
                              content="After reward has been redeemed this many times, the mission will become oversubscribed"
                            />
                          </div>
                          <ToggleSwitch
                            name="hasRedeemLimit"
                            setValue={(name, e) => {
                              setValue(name, e);
                              setHasRedeemLimit(e);
                              if (!e) {
                                unregister("totalRedeemLimit");
                                const limitInput = document.getElementById(
                                  "totalRedeemLimit",
                                ) as HTMLInputElement;
                                if (limitInput) {
                                  limitInput.value = "";
                                  setRedeemLimit(0);
                                }
                              }
                            }}
                          />
                        </div>
                        <div>
                          <TextInput
                            registerInForm={register}
                            name={"totalRedeemLimit"}
                            disabled={!hasRedeemLimit}
                            placeholder="No limit"
                            id="totalRedeemLimit"
                            onChange={(e: any) =>
                              setRedeemLimit(e.target.value)
                            }
                            errorMessage={
                              errors?.totalRedeemLimit?.message as string
                            }
                            validation={
                              hasRedeemLimit
                                ? {
                                    required: "Limit must be more than 1",
                                    pattern: {
                                      value: /^[1-9][0-9]*$/,
                                      message: "Limit must be more than 1",
                                    },
                                  }
                                : false
                            }
                          />
                        </div>
                      </div>
                      {fulfilmentOption === "In-venue" &&
                        type === "Offline Gift" && (
                          <>
                            <TextInput
                              registerInForm={register}
                              name={"venueName"}
                              label="Venue Name"
                              placeholder="e.g The Roundhouse"
                              id="venueName"
                              errorMessage={
                                errors?.venueName?.message as string
                              }
                              validation={{
                                required: "Venue name is required",
                              }}
                            />
                            <TextInput
                              registerInForm={register}
                              name={"venueAddress"}
                              label="Venue Address"
                              placeholder="Enter address here"
                              id="venueAddress"
                              errorMessage={
                                errors?.venueAddress?.message as string
                              }
                              validation={{
                                required: "Venue address is required",
                              }}
                            />
                            <TextInput
                              registerInForm={register}
                              name={"venueLocationLink"}
                              label="Venue Location Link"
                              placeholder="e.g. a Google Maps link"
                              id="venueLocationLink"
                              errorMessage={
                                errors?.venueLocationLink?.message as string
                              }
                              validation={{
                                required: "Venue Location Link is required",
                              }}
                            />
                          </>
                        )}
                      {type === "Offline Gift" &&
                        fulfilmentOption === "3rd party" &&
                        hasRedeemLimit && (
                          <>
                            <div className="flex justify-between items-center">
                              <p className="body-lg text-surface-900">
                                Redemption happens with coupon code(s)?
                              </p>
                              <ToggleSwitch
                                name="hasCouponeCodes"
                                setValue={(name, e) => {
                                  setValue(name, e);
                                  setHasCouponeCodes(e);
                                }}
                              />
                            </div>
                            {hasCouponeCodes && (
                              <>
                                <div className="flex justify-between items-center">
                                  <p className="body-lg text-surface-900">
                                    Are all coupon codes unique?
                                  </p>
                                  <ToggleSwitch
                                    name="uniqueCouponCode"
                                    setValue={(name, e) => {
                                      setValue(name, e);
                                      setHasUniqueCouponCode(e);
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col w-full gap-2">
                                  {couponCodes.map(
                                    (elem: string, index: number) => (
                                      <TextInput
                                        key={`#${index + 1}-Coupon-Code`}
                                        name={`#${index + 1}-Coupon-Code`}
                                        placeholder={
                                          hasUniqueCouponCode
                                            ? `#${index + 1} Coupon Code`
                                            : "Coupon Code"
                                        }
                                        onChange={(e: any) =>
                                          handleCouponUpdate(
                                            e.target.value,
                                            index,
                                          )
                                        }
                                        errorMessage={
                                          errors &&
                                          (errors[`#${index + 1}-Coupon-Code`]
                                            ?.message as string)
                                        }
                                        validation={{
                                          required: "Venue address is required",
                                        }}
                                      />
                                    ),
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      {type === "Offline Gift" &&
                        fulfilmentOption === "3rd party" && (
                          <Toast content="We will warn users to not redeem this reward until they are at the right time and place" />
                        )}
                      <TextAreaInput
                        registerInForm={register}
                        name={"disclaimer"}
                        placeholder="e.g. You can only redeem the reward for this mission 3 times."
                        label="Rewards disclaimer"
                        labelClassName="body-lg text-surface-900"
                        id="disclaimer"
                        errorMessage={errors?.disclaimer?.message as string}
                        validation={{ required: "Disclaimer is required" }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="fixed bottom-0 left-0 w-full py-4 px-6">
              {isCreateRewardPending || isUpdateRewardPending ? (
                <div className="flex justify-center items-center">
                  <Loader size="md" />
                </div>
              ) : (
                <Button
                  className="px-5 py-3 w-full"
                  type="submit"
                  disabled={
                    !type || (type === "Offline Gift" && !fulfilmentOption)
                  }
                >
                  Save Details
                </Button>
              )}
            </div>
            {(isCreateRewardError || isUpdateRewardError) && (
              <p>{createRewardError?.message || updateRewardError?.message}</p>
            )}
            {(isCreateRewardSuccess || isUpdateRewardSuccess) && (
              <p>Reward saved successfully</p>
            )}
          </div>
        </form>
      </div>
    </BoonModal>
  );
};

export default RewardModal;
