import BoonModal from "../Modal";
import TelegramLoginButton from "../TelegramLogin";

export const TelegramConnectModal = ({
  userId,
  onClose,
}: {
  userId: string;
  onClose: () => void;
}) => {
  return (
    <BoonModal onClose={onClose}>
      <div className="flex flex-col gap-10 justify-center items-center">
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-title-lg">Connect to telegram is required</h3>
          <p className="text-body-lg">
            Connecting your Telegram account is necessary to proceed
          </p>
        </div>
        {userId && <TelegramLoginButton userId={userId} />}
      </div>
    </BoonModal>
  );
};
