import DefaultBackground from "../../assets/images/defaultBackground.png";
import TagCard from "../TagInput/TagCard";
import moment from "moment";

export interface PreviewBoxTypes {
  values: Record<string, string>;
}

const PreviewBox = ({ values }: PreviewBoxTypes) => {
  const coverImage =
    values &&
    values.coverImageUrl &&
    typeof values.coverImageUrl === "object" &&
    "name" in values.coverImageUrl
      ? URL.createObjectURL(values.coverImageUrl as File)
      : values.imageUrl || DefaultBackground;

    const validFrom = new Date(values.validFrom);
    const validTo = new Date(values.validTo);

  return (
    <div className="max-w-sm w-full overflow-hidden text-wrap border-surface-200 border-solid border rounded-2xl bg-surface-50 h-full pb-6">
      <div className="w-full relative flex justify-center">
        <div className="absolute w-full h-full bg-gradient-to-t from-black/10 from-0%" />
        <img
          src={coverImage}
          className="w-full h-36 object-contain rounded-t-2xl"
          alt="default_bg"
        />
      </div>
      <div className="px-4 pt-4 w-full flex flex-col gap-4 overflow-hidden">
        <div className="flex flex-col gap-3 text-start w-full">
          <h3 className="title-lg-bold break-words">{values.mission_name}</h3>
          <p className="body-lg-regular break-words text-wrap w-full overflow-hidden text-ellipsis">
            {values.description}
          </p>
        </div>
        <div className="w-full flex flex-wrap gap-2">
          {values && Array.isArray(values.tags)
            ? values.tags.map((tag, index) => (
                <TagCard key={index} value={tag} variant="medium" />
              ))
            : ""}
        </div>
        <div className="flex items-center flex-wrap gap-2">
          {(values.validFrom || values.validTo) && (
            <>
              <p>
                {values.validFrom 
                  ? moment(validFrom).format('DD / MM / YYYY')
                  : '∞'
                }
              </p>
              <span>-</span>
              <p>
                {values.validTo
                  ? moment(validTo).format('DD / MM / YYYY')
                  : '∞'
                }
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewBox;
