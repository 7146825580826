import Navbar from "./components/Navbar";
import { Routes, Route, useLocation } from "react-router-dom";

import RestrictedPage from "./pages/utils/RestrictedPage";
import Dashboard from "./pages/dashboard";
import Community, { CommunityWithUsername } from "./pages/community";
import Landing from "./pages/landing";
import { AuthProvider } from "./context/AuthContext";
import CommunityManagement from "./pages/community-management";
import { CommunityManagementProvider, UserManagementProvider } from "./context";
import { nonNavbarUrls } from "./constants";
import UserManagement from "./pages/user-management";
import Discovery from "./pages/discovery";
import Test from "./pages/test";
import Mission from "./pages/mission";
import { RewardProvider } from "./context/RewardContext";
import PrivacyAndTerms from "./pages/privacy-and-terms";
import RestrictedByUpdatedPrivacyPolicy from "./pages/utils/RestrictedByPrivacy";
import PrivacyProvider from "./context/PrivacyContext";
import VettedMissions from "./pages/vetted-missions";
import Contact from "./pages/contact";

function App() {
  const { pathname } = useLocation();
  const displayNavbar = !nonNavbarUrls.some((url) =>
    url === "/" ? pathname === "/" : pathname.includes(url),
  );

  return (
    <AuthProvider>
      <CommunityManagementProvider>
        <UserManagementProvider>
          <RewardProvider>
            <PrivacyProvider>
              <div id="AppElement" className="w-full h-full flex flex-col">
                {displayNavbar && <Navbar />}

                <Routes>
                  <Route
                    path="/:username"
                    element={<CommunityWithUsername />}
                  />
                  <Route path="/" element={<Landing />} />

                  <Route
                    path="/dashboard"
                    element={
                      <RestrictedPage>
                        <Dashboard />
                      </RestrictedPage>
                    }
                  />
                  <Route path="/community/:_id" element={<Community />} />

                  <Route path="/discovery" element={<Discovery />} />
                  <Route
                    path="/community/management/:_id/*"
                    element={
                      <RestrictedPage>
                        <RestrictedByUpdatedPrivacyPolicy pageName="community/management">
                          <CommunityManagement />
                        </RestrictedByUpdatedPrivacyPolicy>
                      </RestrictedPage>
                    }
                  />
                  <Route
                    path="/user/management"
                    element={
                      <RestrictedPage>
                        <UserManagement />
                      </RestrictedPage>
                    }
                  />
                  <Route
                    path="/mission/:_id"
                    element={
                      <RestrictedByUpdatedPrivacyPolicy pageName="mission">
                        <Mission />
                      </RestrictedByUpdatedPrivacyPolicy>
                    }
                  />
                  <Route
                    path="/user_admin"
                    element={
                      <RestrictedPage allowedRoles={["admin"]}>
                        <Test />
                      </RestrictedPage>
                    }
                  />
                   <Route
                    path="/vetted_missions"
                    element={
                      <RestrictedPage allowedRoles={["admin"]}>
                        <VettedMissions />
                      </RestrictedPage>
                    }
                  />
                  <Route
                    path="/privacy-and-terms"
                    element={<PrivacyAndTerms />}
                  />
                  <Route
                    path="/contact"
                    element={<Contact />}
                  />
                </Routes>
              </div>
            </PrivacyProvider>
          </RewardProvider>
        </UserManagementProvider>
      </CommunityManagementProvider>
    </AuthProvider>
  );
}

export default App;
function setActiveAuth(activeModal: string) {
  throw new Error("Function not implemented.");
}

