import { useState } from "react";
import HorizontalMenu from "../../../assets/icons/horizontalMenu.jsx";
import MenuIcon from "../../../assets/icons/menu.jsx";
import { Mission } from "../../../hooks/useCommunity/index.js";
import ScoreCard from "../../CommunityCards/ScoreCard";
import Popover from "../../Popover";
import useDuplicateMission from "../../../hooks/useMissions/useDuplicateMission";
import DeactivateMissionModal from "../EditMission/DeactivateModal";
import MissionStatsModal from "../EditMission/MissionStatsModal";
import MissionStatusTag from "./MissionStatusTag";
import {
  useArchiveMission,
  useUnarchiveMission,
} from "../../../hooks/useMissions/useArchivedMissions";
import ArchiveMissionModal from "../../ArchivedMission";
import { useNavigate, useParams } from "react-router-dom";

function removeElClass(elements: any, elClass: any, elClass2: any) {
  elements = [].slice.call(elements);
  for (const el of elements) {
    el.classList.remove(elClass);
    el.classList.remove(elClass2);
  }
  return elements;
}

function setHoverEffect(event: any) {
  const childList = removeElClass(
    event.currentTarget.parentElement.children,
    "border-surface-250",
    "border-primary-500"
  );
  if (
    !childList.some((child: any) => child.classList.contains("sortable-chosen"))
  ) {
    event.currentTarget.classList.add("border-primary-500");
  }
}

const DropdownItem = ({
  text,
  onClick,
  danger,
  success,
}: {
  text: string;
  onClick?: () => void;
  className?: string;
  danger?: boolean;
  success?: boolean;
}) => {
  return (
    <p
      className={`text-body-md hover:bg-surface-150 px-6 py-2 w-full cursor-pointer ${danger ? "text-danger-200" : success ? "text-success-200" : "text-surface-900"}`}
      onClick={onClick}
    >
      {text}
    </p>
  );
};

const DropdownContent = ({
  missionId,
  setMissionId,
  setModal,
  duplicateMission,
  missionActive,
  archived,
  navigate,
}: {
  missionId: string;
  setMissionId: (data: string) => void;
  setModal: (data: string) => void;
  duplicateMission: () => void;
  missionActive: boolean;
  archived: boolean;
  navigate: (path: string) => void;
}) => {
  return (
    <div className="flex flex-col border border-solid border-surface-250 rounded-xl ease-in-out py-6 bg-surface-50">
      <DropdownItem
        text="Copy mission link"
        onClick={() => {
          const currentPath = window.location.origin;
          navigator.clipboard.writeText(`${currentPath}/mission/${missionId}`);
        }}
      />
      <DropdownItem
        text="Edit Mission"
        onClick={() => setMissionId(missionId)}
      />
      <DropdownItem
        text="See mission stats"
        onClick={() => navigate(`${missionId}/stats`)}
      />
      <DropdownItem text="Duplicate mission" onClick={duplicateMission} />
      {archived ? (
        <DropdownItem
          text="Unarchive"
          success={true}
          onClick={() => setModal("archive")}
        />
      ) : (
        <DropdownItem
          text="Archive"
          danger={true}
          onClick={() => setModal("archive")}
        />
      )}
      <DropdownItem
        text={missionActive ? "Deactivate mission" : "Activate mission"}
        danger={missionActive}
        success={!missionActive}
        onClick={() => setModal("deactivate")}
      />
    </div>
  );
};

const CommunityManagerSingleMission = ({
  mission,
  setMissionId,
  archived = false,
}: {
  mission: Mission;
  setMissionId: (data: string) => void;
  archived?: boolean;
}) => {
  const [modal, setModal] = useState<string | undefined>();
  const { duplicateMission } = useDuplicateMission({ missionId: mission._id });
  const { mutate: archiveMission, isPending: archiveMissionPending } =
    useArchiveMission();
  const { mutate: unarchiveMission, isPending: unarchiveMissionPending } =
    useUnarchiveMission();
  const navigate = useNavigate();
  const { _id: communityId } = useParams();

  return (
    <div
      className="flex rounded-xl border border-solid border-surface-250 justify-between p-4 cursor-pointer"
      onMouseOver={setHoverEffect}
      onDragEnd={setHoverEffect}
      onDrag={setHoverEffect}
    >
      {modal === "deactivate" && (
        <DeactivateMissionModal
          onClose={() => setModal(undefined)}
          missionActive={mission.active}
          missionId={mission._id}
        />
      )}

      {modal === "archive" && (
        <ArchiveMissionModal
          onClose={() => setModal(undefined)}
          missionName={mission.name}
          archived={archived}
          isPending={
            !archived ? archiveMissionPending : unarchiveMissionPending
          }
          onSubmit={
            !archived
              ? () =>
                  archiveMission(mission._id as any, {
                    onSuccess: () => setModal(undefined),
                  })
              : () =>
                  unarchiveMission(mission._id as any, {
                    onSuccess: () => setModal(undefined),
                  })
          }
        />
      )}
      <div className="flex gap-3 items-center">
        <MenuIcon />
        <p className="body-lg-medium text-surface-900">{mission.name}</p>
        <p className="rounded-full bg-[#DFFAFE] body-md-medium text-[#55A8D9] px-2 py-1.5">
          {mission.tasks?.length || 0}
        </p>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex gap-3">
          <MissionStatusTag active={mission.active} />
          <MissionStatusTag launched={mission.launched} />
        </div>
        <Popover
          className="cursor-pointer"
          content={
            <DropdownContent
              missionId={mission._id}
              setMissionId={setMissionId}
              setModal={setModal}
              duplicateMission={duplicateMission}
              missionActive={mission.active}
              archived={archived}
              navigate={navigate}
            />
          }
        >
          <HorizontalMenu />
        </Popover>
      </div>
    </div>
  );
};

export default CommunityManagerSingleMission;
