import { useRef, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { useFileUpload } from "../../hooks/useFileUpload";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";
import Button, { ButtonType } from "../Button";
import EmojiPicker from "emoji-picker-react";
import SmileIcon from "../../assets/icons/smile";
import Popover from "../Popover";
import { Cropper, type ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Loader } from "../Loader";

export interface UploadFileProps {
  label?: string;
  name: string;
  validation?: any;
  errorMessage?: string;
  setValue?: (name: string, value: any) => void;
  control: any;
  onChange?: (input: any) => void;
  description?: string;
  defaultValue?: string;
  tooltipContent?: string;
  emojiable?: boolean;
  id?: string;
  isSquare?: boolean;
  enableCropping?: boolean;
}

const dataURLToFile = (dataUrl: string, fileName: string) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "image/png";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};


const UploadFile = ({
  label,
  name,
  validation,
  setValue = () => ({}),
  control,
  errorMessage,
  description,
  onChange,
  defaultValue,
  tooltipContent,
  emojiable = false,
  id,
  isSquare = false,
  enableCropping = true,
}: UploadFileProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [emoji, setEmoji] = useState<string | null>(null);
  const { uploadFile, isUploading } = useFileUpload();
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isCropUploading, setIsCropUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    return () => {
      setFile(null);
      setEmoji(null);
      setIsCropping(false);
      setIsCropUploading(false);
      setError(null);
    };
  }, []);

  const inputClasses = classNames(
    "w-full p-4 border-2 rounded-2xl border-dotted cursor-pointer",
    {
      "border-red-500 opacity-100": errorMessage,
      "border-surface-250 opacity-80": !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  const handleUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFile(file);
      
      if (enableCropping) {
        setIsCropping(true);
      } else {
        // Direct upload without cropping
        const { success, fileUrl } = await uploadFile(file);
        if (success) {
          console.log("File uploaded successfully.");
          setValue(name, fileUrl);
          onChange?.({ target: { name, files: [file] } });
        } else {
          console.error("Failed to upload file.");
        }
      }
    }
  };

  const handleCrop = async () => {
    if (!cropperRef.current || !file) return;
    
    setIsCropUploading(true);
    
    try {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      if (!canvas) return;

      const croppedImage = canvas.toDataURL();
      const croppedFile = dataURLToFile(croppedImage, file.name ?? "cropped-image.png");
      
      setIsCropping(false);
      
      const { success, fileUrl } = await uploadFile(croppedFile);
      if (success) {
        console.log("File uploaded successfully.");
        setFile(croppedFile);
        onChange?.({ target: { name, files: [croppedFile] } }); 
        setValue(name, fileUrl);
      } else {
        console.error("Failed to upload file.");
      }
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setIsCropUploading(false);
    }
  };

  const cropperOptions: any = {
    src: file ? URL.createObjectURL(file) : undefined,
    style: { height: 400, width: "100%" },
    aspectRatio: isSquare ? 1 : 3 / 1,
    guides: true,
    dragMode: 'move',
    zoomable: true,
    scalable: false,
    rotatable: false,
    movable: true,
    viewMode: 1,
    cropBoxMovable: false,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
    autoCropArea: 0.7,
    background: true,
    modal: true,
    highlight: false,
    center: true,
  };

  return (
    <div className="w-full flex flex-col gap-2 relative">
      <div className="flex gap-2 w-full justify-between items-center">
        <div className="flex items-center w-full gap-2">
          {label && (
            <p className={`body-lg font-medium text-surface-700`}>
              {label} {validation ? "*" : ""}
            </p>
          )}
          {tooltipContent && (
            <>
              <a id={id}>
                <QuestionMarkIcon />
              </a>
              <BoonTooltip id={id} content={tooltipContent} />
            </>
          )}
        </div>
        {emojiable ? (
          <Popover
            className="flex items-center p-0 m-0 transition-all duration-500"
            closeOnClick={true}
            content={
              <EmojiPicker
                onEmojiClick={(e) => {
                  if (file) return;
                  if (emoji === e.imageUrl) return setEmoji(null);

                  setEmoji(e.imageUrl);
                  setValue("rewardEmoji", e.imageUrl);
                }}
              />
            }
          >
            <Button
              type="button"
              category={ButtonType.SECONDARY}
              className="text-nowrap gap-2 w-44 flex items-center"
            >
              {emoji ? (
                <img src={emoji as string} className="w-5 " alt="emoji" />
              ) : (
                <SmileIcon width={25} height={25} />
              )}
              <p>Choose Emoji</p>
            </Button>
          </Popover>
        ) : (
          ""
        )}
      </div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: { value: validation, message: validation?.required },
        }}
        defaultValue={file}
        render={() => (
          <Dropzone onDrop={handleUpload}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: inputClasses })}>
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className="text-center">
                  {isUploading ? (
                    <div className="flex flex-col items-center gap-2">
                      <Loader size="md" />
                      <p className="text-primary-500">Uploading file...</p>
                    </div>
                  ) : (
                    <>
                      <h3 className="text-primary-250 cursor-pointer">
                        <span className="text-primary-500">Click to upload</span>{" "}
                        or drag and drop
                      </h3>
                      <p className="text-xs">{file ? file.name : description}</p>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        )}
      />

      {enableCropping && isCropping && file && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg w-[90%] max-w-2xl">
            <Cropper
              ref={cropperRef}
              {...cropperOptions}
            />
            <div className="flex justify-center gap-2 mt-2">
              <button
                type="button"
                onClick={() => setIsCropping(false)}
                className="bg-gray-300 text-black py-1 px-4 rounded-md"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleCrop}
                disabled={isCropUploading}
                className={`py-1 px-4 rounded-md ${
                  isCropUploading 
                  ? 'bg-primary-300 cursor-not-allowed' 
                  : 'bg-primary-500 hover:bg-primary-600'
                } text-white`}
              >
                {isCropUploading ? 'Processing...' : 'Crop & Upload'}
              </button>
            </div>
          </div>
        </div>
      )}

      {errorMessage && <p className={errorClasses}>{errorMessage}</p>}
    </div>
  );
};

export default UploadFile;
