import { useEffect } from "react";
import { API_URL, TELEGRAM_BOT_NAME } from "../../constants";

interface TelegramLoginButtonProps {
  userId: string;
}

const TelegramLoginButton = ({ userId }: TelegramLoginButtonProps) => {
  useEffect(() => {
    if (userId) {
      const telegramWidget = document.getElementById("telegram-login-widget");

      if (telegramWidget) {
        // Clear any existing content
        telegramWidget.innerHTML = "";

        // Create script for the Telegram widget
        const widgetScript = document.createElement("script");
        widgetScript.async = true;
        widgetScript.src = "https://telegram.org/js/telegram-widget.js?22";
        widgetScript.setAttribute("data-telegram-login", TELEGRAM_BOT_NAME);
        widgetScript.setAttribute("data-size", "large");
        widgetScript.setAttribute("data-userpic", "true");
        widgetScript.setAttribute(
          "data-auth-url",
          `${API_URL}/api/telegram/callback?userId=${userId}`
        );
        widgetScript.setAttribute("data-request-access", "write");

        // Append script to the widget container
        telegramWidget.appendChild(widgetScript);
      }
    }
  }, [userId]);

  return <div id="telegram-login-widget"></div>;
};

export default TelegramLoginButton;