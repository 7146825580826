interface RibbonProps {
  text: string;
  className?: string;
}

export const Ribbon = ({ text, className = '' }: RibbonProps) => {
  return (
    <div className={`
      absolute top-[10px] right-[-10px]
      py-2 pl-[25px] pr-[10px] pb-[15px]
      z-50
      bg-primary-500 text-white
      shadow-[0_-10px_0_inset_rgba(0,0,0,0.2)]
      [clip-path:polygon(0_0,100%_0,100%_calc(100%-10px),calc(100%-10px)_100%,calc(100%-10px)_calc(100%-10px),0_calc(100%-10px),15px_calc(50%-5px))]
      after:absolute after:content-[''] after:right-0 after:bottom-[-10px]
      after:border-[5px] after:border-r-primary-800 after:border-t-primary-800
      after:border-l-transparent after:border-b-transparent
      ${className}
    `}>
      <p className="text-md">{text}</p>
    </div>
  );
}; 