import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import Button, { ButtonType } from "../../Button";
import Table, { TableColumn } from "../../Table";
import useGetMissionStats from "../../../hooks/useMissions/useGetMissionStats";
import useCompleteMissionVerification from "../../../hooks/useMissions/useCompleteMissionVerification";
import useGetAllMissionStats from "../../../hooks/useMissions/useGetAllMissionStats";
import * as XLSX from "xlsx";
import { Loader } from "../../Loader";
import ArrowSide from "../../../assets/icons/arrowSide";
import toast from "react-hot-toast";
import Pagination from "../../Pagination";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants";

const StatColumn = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col border border-solid border-surface-150 rounded-lg gap-2.5 pb-3 w-full">
    <p className="bg-surface-150 w-full p-3 body-sm-medium text-surface-700">
      {label}
    </p>
    <p className="pl-3 title-lg-medium text-surface-900">{value}</p>
  </div>
);

// Status options for the dropdown
type VerificationStatus = "TBC" | "Verified" | "Failed";

// Map frontend status to backend status
const statusToBackendMap = {
  TBC: "in_progress",
  Verified: "completed",
  Failed: "failed",
};

// Map backend status to frontend status
const backendToStatusMap = {
  in_progress: "TBC",
  completed: "Verified",
  failed: "Failed",
} as const;

// Helper function to fix URLs with double slashes
const fixImageUrl = (url: string) => {
  if (!url) return url;
  // Replace double slashes after the protocol (http:// or https://)
  return url
    .replace(/(https?:\/\/)\/+/g, "$1")
    .replace(/(https?:\/\/)([^/]+)\/+/g, "$1$2/");
};

const calculatePercentage = (number1?: number, number2?: number) =>
  number1 && number2 ? `${Math.floor((number1 / number2) * 100)}%` : "0%";

// Task screenshot carousel component
const TaskScreenshotCarousel = ({ tasks }: { tasks: any[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const tasksWithScreenshots =
    tasks?.filter((task) => task?.screenshotImageUrl) || [];

  const goToPrevious = useCallback(() => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? tasksWithScreenshots.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, tasksWithScreenshots.length]);

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === tasksWithScreenshots.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, tasksWithScreenshots.length]);

  if (!tasksWithScreenshots.length) {
    return (
      <div className="bg-gray-100 rounded-md p-4 flex items-center justify-center h-[160px] w-full">
        <span className="text-gray-500">No screenshots available</span>
      </div>
    );
  }

  const currentTask = tasksWithScreenshots[currentIndex];
  const taskId = currentTask?.taskId?.toString() || "";
  const shortTaskId = taskId.slice(-8);

  return (
    <div className="relative w-full border border-gray-200 rounded-md overflow-hidden">
      <div className="w-full text-white">
        <div className="bg-black text-white px-3 py-1 font-medium text-sm">
          Task {shortTaskId}
        </div>
        <div className="w-full h-[160px] relative flex items-center justify-center bg-black">
          <img
            src={fixImageUrl(currentTask?.screenshotImageUrl)}
            alt={`Task proof ${currentIndex + 1}`}
            className="w-full h-full object-contain"
          />
          <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-md text-xs">
            {currentIndex + 1}/{tasksWithScreenshots.length}
          </div>
        </div>
      </div>

      {tasksWithScreenshots.length > 1 && (
        <>
          <button
            onClick={goToPrevious}
            className="absolute left-2 top-[90px] -translate-y-1/2 bg-black bg-opacity-30 text-white p-1.5 rounded-full hover:bg-opacity-50 transition-all"
            aria-label="Previous screenshot"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={goToNext}
            className="absolute right-2 top-[90px] -translate-y-1/2 bg-black bg-opacity-30 text-white p-1.5 rounded-full hover:bg-opacity-50 transition-all"
            aria-label="Next screenshot"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </>
      )}
    </div>
  );
};

const MissionStats = () => {
  const { missionId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Record<string, boolean>>(
    {}
  );
  const [userStatuses, setUserStatuses] = useState<
    Record<string, VerificationStatus>
  >({});
  const [hasSelectedUsers, setHasSelectedUsers] = useState(false);
  const [savedStatuses, setSavedStatuses] = useState<
    Record<string, VerificationStatus>
  >({});
  const [originalBackendStatuses, setOriginalBackendStatuses] = useState<
    Record<string, string>
  >({});
  const [statusesSaved, setStatusesSaved] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [testMode, setTestMode] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const { data, isLoading, refetch } = useGetMissionStats({
    _id: missionId || "",
    page: currentPage,
    limit: itemsPerPage,
    mockData: testMode,
  });

  const { mutate: completeVerification, isPending: isSubmitting } =
    useCompleteMissionVerification();

  const { data: allUsersData, isLoading: isLoadingAllUsers } =
    useGetAllMissionStats({
      _id: missionId || "",
      mockData: testMode,
    });

  // Initialize user statuses from backend data when it loads
  useEffect(() => {
    if (data?.users) {
      const initialStatuses: Record<string, VerificationStatus> = {};
      const backendStatuses: Record<string, string> = {};

      data.users.forEach((user: any) => {
        // Get the status from user's mission status or default to TBC
        const missionStatus = user.missionStatus || "in_progress";
        initialStatuses[user._id] =
          backendToStatusMap[
            missionStatus as keyof typeof backendToStatusMap
          ] || "TBC";

        // Store the original backend status
        backendStatuses[user._id] = missionStatus;
      });

      setUserStatuses(initialStatuses);
      setSavedStatuses(initialStatuses);
      setOriginalBackendStatuses(backendStatuses);
      setStatusesSaved(false);
    }
  }, [data]);

  // Reset saved status when mission ID changes
  useEffect(() => {
    setStatusesSaved(false);
  }, [missionId]);

  // Update getStatistics method to use the overall stats from the API
  const getStatistics = () => {
    // If we have overall stats from the API, use those
    if (data?.stats) {
      return {
        finishers: data.stats.totalUsers || 0,
        finishersChecked: data.stats.totalUsers || 0, // All users are "checked" in the stats
        finishersVerified: data.stats.totalVerified || 0,
        finishersVerifiedPercentage: data.stats.verificationPercentage || 0,
        overallVerifiedPercentage: data.stats.verificationPercentage || 0,
        statusCounts: data.stats.statusCounts || {
          tbc: 0,
          verified: 0,
          failed: 0,
        },
      };
    }

    // Fallback to calculating from the paginated data if overall stats are not available
    const finishers = data?.totalJoined || 0;
    const finishersChecked = finishers; // All users are displayed in the table
    const finishersVerified = data?.totalCompleted || 0;
    const finishersVerifiedPercentage =
      finishersChecked > 0
        ? Math.floor((finishersVerified / finishersChecked) * 100)
        : 0;
    const overallVerifiedPercentage =
      finishers > 0 ? Math.floor((finishersVerified / finishers) * 100) : 0;

    return {
      finishers,
      finishersChecked,
      finishersVerified,
      finishersVerifiedPercentage,
      overallVerifiedPercentage,
      statusCounts: {
        tbc: 0,
        verified: finishersVerified,
        failed: 0,
      },
    };
  };

  // Get statistics
  const stats = getStatistics();

  // Update hasSelectedUsers whenever selectedUsers changes
  useEffect(() => {
    const hasSelected = Object.values(selectedUsers).some(
      (selected) => selected
    );
    setHasSelectedUsers(hasSelected);
  }, [selectedUsers]);

  // Handle individual user selection
  const handleUserSelect = (userId: string) => {
    // Don't allow selection of users with non-editable statuses
    if (isStatusNotEditable(userId)) return;

    setSelectedUsers((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  // Calculate if all selectable users (editable statuses) are selected
  const areAllSelectableUsersSelected = () => {
    if (!data?.users) return false;

    const selectableUsers = data.users.filter((user: any) => {
      return !isStatusNotEditable(user._id);
    });

    if (selectableUsers.length === 0) return false;

    return selectableUsers.every((user: any) => selectedUsers[user._id]);
  };

  // Handle select all checkbox
  const handleSelectAll = () => {
    const allSelected = areAllSelectableUsersSelected();
    setSelectAll(!allSelected);

    const newSelectedUsers: Record<string, boolean> = {};
    if (data?.users) {
      data.users.forEach((user: any) => {
        // Only select users with editable statuses
        if (!isStatusNotEditable(user._id)) {
          newSelectedUsers[user._id] = !allSelected;
        }
      });
    }

    setSelectedUsers(newSelectedUsers);
  };

  // Check if status is not editable (already completed/failed in backend or saved as Verified/Failed)
  const isStatusNotEditable = (userId: string) => {
    // If it's already completed or failed in the backend, it can't be edited
    const backendStatus = originalBackendStatuses[userId];
    if (backendStatus === "completed" || backendStatus === "failed") {
      return true;
    }

    // If it's been saved as Verified or Failed in this session, it can't be edited
    const status = savedStatuses[userId];
    return statusesSaved && (status === "Verified" || status === "Failed");
  };

  // Check if status has changed but not yet saved
  const hasStatusChanged = (userId: string) => {
    return userStatuses[userId] !== savedStatuses[userId];
  };

  // Handle status change for a user
  const handleStatusChange = (userId: string, status: VerificationStatus) => {
    // Don't allow status changes for records that are already completed/failed
    if (isStatusNotEditable(userId)) return;

    setUserStatuses((prev) => ({
      ...prev,
      [userId]: status,
    }));
  };

  // Batch mark selected users as verified
  const markSelectedAsVerified = () => {
    try {
      const selectedUserIds = Object.keys(selectedUsers).filter((id) => {
        return selectedUsers[id] && !isStatusNotEditable(id);
      });
      if (selectedUserIds.length === 0) {
        toast.error("No eligible users selected");
        return;
      }

      const updatedStatuses = { ...userStatuses };
      selectedUserIds.forEach((userId) => {
        updatedStatuses[userId] = "Verified";
      });

      setUserStatuses(updatedStatuses);
      toast.success(`${selectedUserIds.length} users marked as verified`);
    } catch (error) {
      console.error("Error marking users as verified:", error);
      toast.error("Failed to mark users as verified");
    }
  };

  // Batch mark selected users as failed
  const markSelectedAsFailed = () => {
    try {
      const selectedUserIds = Object.keys(selectedUsers).filter((id) => {
        return selectedUsers[id] && !isStatusNotEditable(id);
      });
      if (selectedUserIds.length === 0) {
        toast.error("No eligible users selected");
        return;
      }

      const updatedStatuses = { ...userStatuses };
      selectedUserIds.forEach((userId) => {
        updatedStatuses[userId] = "Failed";
      });

      setUserStatuses(updatedStatuses);
      toast.success(`${selectedUserIds.length} users marked as failed`);
    } catch (error) {
      console.error("Error marking users as failed:", error);
      toast.error("Failed to mark users as failed");
    }
  };

  // Save verification statuses to backend
  const saveVerificationStatuses = async () => {
    if (!missionId) return;

    // Create payload with all user statuses
    const statusUpdates = Object.entries(userStatuses).map(
      ([userId, status]) => ({
        userId,
        status: statusToBackendMap[status] || "in_progress",
      })
    );

    if (statusUpdates.length === 0) {
      toast.error("No status updates to save");
      return;
    }

    try {
      // Call API to update task and mission statuses
      await completeVerification(
        {
          missionId,
          statusUpdates,
        } as any, // Type assertion to bypass TS checking temporarily
        {
          onSuccess: () => {
            toast.success("Verification statuses saved successfully");
            setSelectedUsers({});
            setSelectAll(false);
            setSavedStatuses({ ...userStatuses });
            setStatusesSaved(true);
          },
          onError: (error: any) => {
            console.error("Error saving verification statuses:", error);
            const errorMessage =
              error?.response?.data?.message ||
              "Failed to save verification statuses";
            toast.error(errorMessage);
          },
        }
      );
    } catch (error: any) {
      console.error("Error saving verification statuses:", error);
      const errorMessage =
        error?.response?.data?.message ||
        "Failed to save verification statuses";
      toast.error(errorMessage);
    }
  };

  // Check if there are any status changes to save
  const hasStatusChanges = () => {
    return Object.keys(userStatuses).some((userId) => hasStatusChanged(userId));
  };

  // Define table columns for user breakdown
  const missionStatsColumns: TableColumn[] = [
    { _id: "select", title: "", type: "custom" },
    { _id: "name", title: "Name", type: "text" },
    { _id: "email", title: "Email", type: "text" },
    { _id: "proof", title: "Proof", type: "custom" },
    { _id: "status", title: "Status", type: "custom" },
    { _id: "locked", title: "Locked & Sent?", type: "custom" },
  ];

  // Process user data for the table
  const missionStatsRows =
    data?.users?.map((user: any) => {
      return {
        _id: user._id,
        name: user.name,
        email: user.email,
        proof: {
          render: () => (
            <div className="flex flex-col gap-2">
              {user.verificationTasks && user.verificationTasks.length > 0 ? (
                <div className="flex flex-col md:flex-row gap-3 items-start">
                  <div className="w-full md:w-[250px]">
                    <TaskScreenshotCarousel tasks={user.verificationTasks} />
                  </div>

                  <div className="flex-1">
                    <ol className="list-decimal pl-6 pr-2 py-2 text-xs">
                      {user.verificationTasks
                        .map((task: any, index: number) => {
                          // Skip tasks without screenshots
                          if (!task.screenshotImageUrl) return null;

                          return (
                            <li key={index} className="mb-1">
                              <a
                                href={fixImageUrl(task.screenshotImageUrl)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                              >
                                View Full Image
                              </a>
                            </li>
                          );
                        })
                        .filter(Boolean)}
                    </ol>
                  </div>
                </div>
              ) : (
                <span className="text-gray-400 text-xs">
                  No verification needed
                </span>
              )}
            </div>
          ),
        },
        status: {
          render: () => {
            // Get current status with fallback
            const currentStatus =
              userStatuses[user._id] ||
              (user.missionStatus
                ? backendToStatusMap[
                    user.missionStatus as keyof typeof backendToStatusMap
                  ]
                : "TBC");

            return (
              <div className="relative">
                <select
                  value={currentStatus}
                  onChange={(e) =>
                    handleStatusChange(
                      user._id,
                      e.target.value as VerificationStatus
                    )
                  }
                  disabled={isStatusNotEditable(user._id)}
                  className={`p-2 rounded border ${
                    currentStatus === "Verified"
                      ? "bg-green-50 text-green-700 border-green-300"
                      : currentStatus === "Failed"
                        ? "bg-red-50 text-red-700 border-red-300"
                        : hasStatusChanged(user._id)
                          ? "bg-blue-50 text-blue-700 border-blue-300"
                          : "bg-gray-50 text-gray-700 border-gray-300"
                  }`}
                  title={
                    isStatusNotEditable(user._id)
                      ? originalBackendStatuses[user._id] === "completed" ||
                        originalBackendStatuses[user._id] === "failed"
                        ? "Status is locked because it's already completed or failed in the system"
                        : "Status is locked and cannot be changed"
                      : hasStatusChanged(user._id)
                        ? "Status changed but not yet saved"
                        : "Status can be changed until you click 'Save Verification Statuses'"
                  }
                >
                  <option value="TBC">TBC</option>
                  <option value="Verified">Verified</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>
            );
          },
        },
        locked: {
          render: () => {
            // Get current status with fallback
            const currentStatus =
              userStatuses[user._id] ||
              (user.missionStatus
                ? backendToStatusMap[
                    user.missionStatus as keyof typeof backendToStatusMap
                  ]
                : "TBC");

            return (
              <div className="flex justify-center relative group">
                {isStatusNotEditable(user._id) && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                    <div className="hidden group-hover:block absolute z-10 bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap -top-8">
                      {originalBackendStatuses[user._id] === "completed" ||
                      originalBackendStatuses[user._id] === "failed"
                        ? "Mission already processed"
                        : "Status locked and saved"}
                    </div>
                  </>
                )}
              </div>
            );
          },
        },
        select: {
          render: () => {
            // Get current status with fallback
            const currentStatus =
              userStatuses[user._id] ||
              (user.missionStatus
                ? backendToStatusMap[
                    user.missionStatus as keyof typeof backendToStatusMap
                  ]
                : "TBC");

            return (
              <input
                type="checkbox"
                checked={selectedUsers[user._id] || false}
                onChange={() => handleUserSelect(user._id)}
                disabled={isStatusNotEditable(user._id)}
                className={`w-4 h-4 ${isStatusNotEditable(user._id) ? "opacity-50 cursor-not-allowed" : ""}`}
              />
            );
          },
        },
      };
    }) || [];

  // Show loading toast when exporting
  useEffect(() => {
    if (isLoadingAllUsers && isExporting) {
      toast.loading("Preparing data for export...", { id: "export-loading" });
    }
  }, [isLoadingAllUsers, isExporting]);

  // Process the data for export once it's loaded
  useEffect(() => {
    if (allUsersData && !isLoadingAllUsers && isExporting) {
      try {
        toast.dismiss("export-loading");

        if (!allUsersData.users || allUsersData.users.length === 0) {
          toast.error("No data available to export!");
          setIsExporting(false);
          return;
        }

        const exportData = allUsersData.users.map((user: any) => {
          // Extract task proof URLs
          const taskProofInfo =
            user?.verificationTasks
              ?.filter((task: any) => task.screenshotImageUrl)
              .map(
                (task: any) =>
                  `${task.name || "Task"}: ${fixImageUrl(task.screenshotImageUrl)}`
              )
              .join("\n") || "";

          return {
            Name: user.name,
            Email: user?.email || "",
            Status: user.missionStatus
              ? backendToStatusMap[
                  user.missionStatus as keyof typeof backendToStatusMap
                ]
              : "TBC",
            CompletedTasks: user?.completedTasks || 0,
            TotalTasks: user?.totalTasks || 0,
            "Task Proofs": taskProofInfo,
          };
        });

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "User Breakdown");
        XLSX.writeFile(workbook, "user_breakdown.xlsx");

        toast.success(
          `Successfully exported ${exportData.length} user records!`
        );
        setIsExporting(false);
      } catch (error) {
        console.error("Error exporting data:", error);
        toast.error("Error exporting data. Please try again.");
        setIsExporting(false);
      }
    }
  }, [allUsersData, isLoadingAllUsers, isExporting, testMode, missionId]);

  // Export user breakdown to Excel
  const exportUserBreakdown = () => {
    setIsExporting(true);
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // Clear selections when changing pages
    setSelectedUsers({});
    setSelectAll(false);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
    // Clear selections
    setSelectedUsers({});
    setSelectAll(false);
  };

  // Custom Table component with select all checkbox in header
  const MissionTable = () => (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-hidden flex flex-col">
        <div className="flex w-full justify-center flex-1">
          <div className="w-full rounded-lg border border-surface-250 overflow-hidden flex flex-col">
            {/* Table header (fixed) */}
            <div className="sticky top-[41px] z-20 bg-surface-50 border-b border-solid border-surface-250">
              <table className="w-full table-fixed">
                <colgroup>
                  <col style={{ width: "40px" }} /> {/* Checkbox column */}
                  <col style={{ width: "150px" }} /> {/* Name column */}
                  <col style={{ width: "200px" }} /> {/* Email column */}
                  <col style={{ width: "auto" }} /> {/* Proof column */}
                  <col style={{ width: "120px" }} /> {/* Status column */}
                  <col style={{ width: "80px" }} /> {/* Locked column */}
                </colgroup>
                <thead>
                  <tr>
                    <th className="px-2 py-3 body-sm-medium text-surface-500 text-start">
                      <input
                        type="checkbox"
                        checked={areAllSelectableUsersSelected()}
                        onChange={handleSelectAll}
                        className="w-4 h-4"
                        title="Select all users with editable status"
                      />
                    </th>
                    {missionStatsColumns.slice(1).map((column) => (
                      <th
                        key={column._id}
                        className="px-2 py-3 body-sm-medium text-surface-500 text-start"
                      >
                        {column.title}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table body (scrollable) */}
            <div className="h-full overflow-y-auto flex-1">
              <table className="w-full table-fixed">
                <colgroup>
                  <col style={{ width: "40px" }} /> {/* Checkbox column */}
                  <col style={{ width: "150px" }} /> {/* Name column */}
                  <col style={{ width: "200px" }} /> {/* Email column */}
                  <col style={{ width: "auto" }} /> {/* Proof column */}
                  <col style={{ width: "120px" }} /> {/* Status column */}
                  <col style={{ width: "80px" }} /> {/* Locked column */}
                </colgroup>
                <tbody>
                  {missionStatsRows?.map((row: any, rowIndex: number) => (
                    <tr
                      key={row._id}
                      className={`${
                        rowIndex % 2 !== 0 ? "bg-surface-50" : "bg-surface-150"
                      }`}
                    >
                      <td className="text-start px-2 py-3 body-sm-medium text-surface-700">
                        <input
                          type="checkbox"
                          checked={selectedUsers[row._id] || false}
                          onChange={() => handleUserSelect(row._id)}
                          disabled={isStatusNotEditable(row._id)}
                          className={`w-4 h-4 ${
                            isStatusNotEditable(row._id)
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                        />
                      </td>
                      {missionStatsColumns.slice(1).map((column) => {
                        if (
                          column.type === "custom" &&
                          row[column._id]?.render
                        ) {
                          return (
                            <td
                              key={column._id}
                              className="text-start px-2 py-3 body-sm-medium text-surface-700"
                            >
                              {row[column._id].render()}
                            </td>
                          );
                        }

                        // For text type columns
                        return (
                          <td
                            key={column._id}
                            className="text-start px-2 py-3 body-sm-medium text-surface-700 truncate"
                            title={row[column._id]} // Add tooltip on hover for truncated text
                          >
                            <p className="truncate">{row[column._id]}</p>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Handle test mode toggle
  const handleTestModeToggle = () => {
    const newTestMode = !testMode;
    setTestMode(newTestMode);
    setCurrentPage(1);
    setSelectedUsers({});
    setSelectAll(false);

    // Reset status-related states
    setUserStatuses({});
    setSavedStatuses({});
    setOriginalBackendStatuses({});
    setStatusesSaved(false);

    // Invalidate the cache for mission stats to force a refetch with new mock data setting
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.MISSION_STATS],
    });
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-surface-50 rounded-xl mx-6 p-6 gap-6 h-[calc(100vh-100px)] mmd:p-2 mmd:mx-0 flex-grow">
      {/* Fixed header */}
      <div className="flex justify-between sticky top-0 z-30 bg-surface-50 pb-3">
        <p className="title-lg-medium text-surface-700">
          Mission Stats: {data?.name || ""}
        </p>
        <div className="flex items-center gap-4">
          {/* <div className="flex items-center">
            <label
              htmlFor="test-mode"
              className="mr-2 text-surface-700 text-sm"
            >
              Test Mode
            </label>
            <div className="relative inline-block w-10 align-middle select-none">
              <input
                type="checkbox"
                name="test-mode"
                id="test-mode"
                checked={testMode}
                onChange={handleTestModeToggle}
                className="sr-only"
              />
              <div
                className={`block h-6 rounded-full transition-colors duration-200 ease-in-out ${testMode ? "bg-primary-500" : "bg-gray-300"}`}
              ></div>
              <div
                className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform duration-200 ease-in-out ${testMode ? "transform translate-x-4" : ""}`}
              ></div>
            </div>
          </div> */}
          <Button
            type="button"
            category={ButtonType.SECONDARY}
            action={() => navigate(-1)}
          >
            <p className="flex items-center gap-2">
              <ArrowSide /> Back
            </p>
          </Button>
        </div>
      </div>

      {/* Main scrollable content area */}
      <div className="flex flex-col overflow-auto flex-grow">
        {/* Overview section - scrollable */}
        <div className="mb-6">
          <p className="body-lg-medium text-surface-700">Overview</p>
          <div className="flex gap-3 w-full mt-2">
            <StatColumn
              label="Mission finishers"
              value={`${stats.finishers}`}
            />
            <StatColumn
              label="Finishers checked"
              value={`${stats.finishersChecked} (${stats.finishers > 0 ? Math.floor((stats.finishersChecked / stats.finishers) * 100) : 0}%)`}
            />
            <StatColumn
              label="Finishers verified from checked %"
              value={`${stats.finishersVerifiedPercentage}%`}
            />
            <StatColumn
              label="Finishers verified overall %"
              value={`${stats.overallVerifiedPercentage}%`}
            />
          </div>
        </div>

        {/* User Breakdown section with sticky header */}
        <div className="flex flex-col flex-grow">
          <p className="body-lg-medium text-surface-700 py-2 sticky top-0 z-20 bg-surface-50">
            User Breakdown
          </p>
          <div className="flex-grow overflow-hidden">
            <MissionTable />
          </div>
        </div>
      </div>

      {/* Fixed bottom pagination */}
      {data?.pagination && (
        <div className="mt-4 sticky bottom-16 z-30 bg-surface-50 pt-2">
          <Pagination
            currentPage={data.pagination.currentPage}
            totalPages={data.pagination.totalPages}
            totalItems={data.pagination.totalItems}
            itemsPerPage={data.pagination.itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      )}

      {/* Fixed bottom action bar */}
      <div className="flex items-center gap-3 mt-2 sticky bottom-0 z-30 bg-surface-50 py-3">
        <Button
          category={ButtonType.SECONDARY}
          className="py-3 px-5"
          onClick={exportUserBreakdown}
          isLoading={isExporting || isLoadingAllUsers}
        >
          Export User Breakdown
        </Button>

        {hasSelectedUsers && (
          <>
            <Button
              className="py-3 px-5"
              onClick={markSelectedAsVerified}
              title="Applies to all selected users without saved final status"
            >
              Mark as Verified
            </Button>
            <Button
              className="py-3 px-5"
              onClick={markSelectedAsFailed}
              title="Applies to all selected users without saved final status"
            >
              Mark as Failed
            </Button>
          </>
        )}
        <Button
          className="py-3 px-5"
          onClick={saveVerificationStatuses}
          disabled={isSubmitting || !hasStatusChanges()}
          isLoading={isSubmitting}
        >
          Save Verification Statuses
        </Button>
      </div>
    </div>
  );
};

export default MissionStats;
