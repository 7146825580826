import { Mission, Reward } from "../../hooks/useCommunity";
import ScoreCard from "../CommunityCards/ScoreCard";
import { DateRangeInfo } from "./DateRangeInfo";

const SingleEntryCriteria = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <div className="flex gap-3 items-center">
    <p className="body-sm text-surface-700">{label}</p>
    <p className="border border-solid border-surface-250 rounded p-1.5 caption text-surface-900 font-semibold">
      {value}
    </p>
  </div>
);

const MissionPageMissionCard = ({
  name,
  description,
  tags,
  rewards,
  imageUrl,
  entryCriteria,
  disclaimer,
  validFrom,
  validTo,
}: Mission) => {
  return (
    <div className="w-full flex flex-col relative p-3 pb-8 gap-6 border border-solid border-surface-250 rounded-xl">
      <img
        src={imageUrl}
        alt="mission"
        className="rounded-md w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <p className="heading-md-medium text-surface-900">{name}</p>
          <p className="body-lg text-surface-700 text-[#4F575E] opacity-50 font-space-grotesk">
            {description}
          </p>
          {entryCriteria && (
            <div className="flex gap-3 items-center">
              <p className="body-lg text-surface-700 items-center">
                Entry criteria:
              </p>
              {entryCriteria.minimumTwitterFollowers && (
                <SingleEntryCriteria
                  label="Followers on X"
                  value={entryCriteria.minimumTwitterFollowers}
                />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 w-full mt-1">
          <div className="flex flex-col w-full gap-4 p-4 rounded-xl bg-[#FBFBFB]">
            <p className="body-md text-surface-900">Mission Disclaimer</p>
            <p className="body-sm text-surface-500">
              {disclaimer || "No Mission Disclaimer!"}
            </p>
          </div>
          {validTo || validFrom ? (
            <DateRangeInfo validFrom={validFrom} validTo={validTo} />
          ) : null}
          <div className="flex gap-2 mmd:flex-col">
            {tags?.map((tag: string, index: number) => (
              <p
                key={`tag-${index}`}
                className="body-sm-medium text-dark-50 p-1.5 rounded bg-surface-250"
              >
                {tag}
              </p>
            ))}
          </div>
          <div className="flex gap-2 mmd:flex-col ">
            {rewards?.map((reward: Reward, index: number) => (
              <ScoreCard key={`${reward._id}-${index}`} score={reward.name} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionPageMissionCard;
