import CreatorForm from "../CreatorForm";
import { useCallback, useEffect, useState } from "react";
import ModalHeader from "../ModalHeader.tsx";
import PreviewBox from "./PreviewBox";
import useCreateCommunity from "../../hooks/useCreateCommunity";
import { SocialNetworkLink } from "../../types";
import { useNavigate } from "react-router-dom";
import { useScreenSize } from "../../hooks";
import { Community } from "../../hooks/useCommunity";
import useUpdateCommunity from "../../hooks/useUpdateCommunity";

export interface CreateCommunityTypes {
  onClose?: () => void;
  defaultValues?: Community;
  updater?: boolean;
}

const CreateCommunity = ({
  onClose,
  defaultValues,
  updater = false,
}: CreateCommunityTypes) => {
  const { isMobile } = useScreenSize();
  const [currentValues, setCurrentValues] = useState<Record<string, string>>(
    {},
  );
  const [socialNetworkLinks, setSocialNetworkLinks] = useState<
    SocialNetworkLink[]
  >([]);

  useEffect(() => {
    if (defaultValues) {
      setCurrentValues(defaultValues as any);
      setSocialNetworkLinks(() => {
        return defaultValues.socials.map((social) => {
          return {
            ...social,
            networkName: social.type,
          };
        });
      });
    }
  }, [defaultValues]);

  const {
    mutate: createCommunity,
    isSuccess: isCreateCommunitySuccess,
    isPending: isCreateCommunityPending,
  } = useCreateCommunity();

  const { mutate: updateCommunity, isSuccess: isUpdateCommunitySuccess } =
    useUpdateCommunity();

  const navigate = useNavigate();

  const handleDelete = (_id?: string) => {
    setSocialNetworkLinks(
      socialNetworkLinks.filter((elem) => elem._id !== _id),
    );
  };

  const handleUpdate = (
    item: "networkName" | "url",
    value: string,
    _id?: string,
  ) => {
    console.log("ITEM: ", item);
    console.log("VALUE: ", value);
    console.log("ID: ", _id);
    setSocialNetworkLinks((prevState) => {
      const tmpState = [...prevState];
      const index = tmpState.findIndex((elem) => elem._id === _id);
      tmpState[index][item] = value;
      return tmpState;
    });
  };

  const handleSubmit = async (data: any) => {
    const payload = {
      ...data,
      url: data.url.replace(window.origin + "/", ""),
      socials: socialNetworkLinks
        .filter((elem) => elem.url)
        .map((elem) => {
          return { type: elem.networkName || "Other", url: elem.url };
        }),
    };

    if (updater) {
      updateCommunity(payload);

      return isUpdateCommunitySuccess;
    }

    createCommunity(payload, {
      onSuccess: (res) => {
        if (onClose) onClose();
        navigate(`/community/management/${res._id}`);
      },
    });

    return isCreateCommunitySuccess;
  };

  const handleChange = useCallback((input: any) => {
    const { name, value, files } = input.target;
    setCurrentValues((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  }, []);

  const formFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Name",
      validation: {
        required: "Name is required",
      },
      onChange: handleChange,
    },
    {
      label: "Logo",
      name: "logoUrl",
      type: "file",
      placeholder: "Click to upload or drag and drop",
      description: "SVG, PNG, (max. 800x400px)",
      validation: {
        required: "Logo is required",
      },
      onChange: handleChange,
      isSquareImage: true,
    },
    {
      label: "Cover Image",
      name: "coverImageUrl",
      type: "file",
      placeholder: "Click to upload or drag and drop",
      description: "SVG, PNG, (max. 800x400px)",
      validation: {
        required: "Cover image is required",
      },
      onChange: handleChange,
    },
    {
      label: "Description",
      name: "description",
      type: "text",
      validation: {
        required: "Description is required",
      },
      placeholder: "Description",
      onChange: handleChange,
    },
    {
      label: "Handle",
      name: "url",
      prefix: window.origin + "/@",
      type: "text",
      validation: {
        required: "Url is required",
      },
      placeholder: window.origin + "/@",
      defaultValue: window.origin + "/@",
      onChange: (e: any) => {
        const value = e.target.value;
        if (!value.includes(window.origin + "/@")) {
          return;
        }
        handleChange(e);
      },
    },
    {
      label: "Choose Tag",
      name: "tags",
      type: "tagable",
      validation: {
        required: "Tags is required",
      },
    },
    {
      label: "Links to socials",
      name: "",
      type: "repeatable",
    },
  ];

  return (
    <div className="flex items-start gap-7 w-full mmd:h-full mmd:flex-col-reverse mmd:items-center">
      <div className="border-surface-200 border-solid border p-6 w-full rounded-2xl bg-surface-50 h-full mmd:rounded-xl">
        <ModalHeader
          title={updater ? "Edit community" : "Create a Community"}
          onClose={onClose}
        />
        <div className="flex flex-col gap-3 overflow-auto h-full mmd:h-[95%]">
          <CreatorForm
            defaultValues={defaultValues}
            formFields={formFields}
            onSubmit={handleSubmit}
            socialNetworkLinks={socialNetworkLinks}
            setSocialNetworkLinks={setSocialNetworkLinks}
            submitButtonTitle={updater ? "Save Details" : "create community"}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            isPending={isCreateCommunityPending}
            disableMaxHeight={isMobile || updater}
          />
          {isUpdateCommunitySuccess ? (
            <p className="text-green-600">community updated succesfuly</p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <PreviewBox
          values={currentValues}
          socialNetworkLinks={socialNetworkLinks}
        />
      </div>
    </div>
  );
};

export default CreateCommunity;
