export const API_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://whale-app-uvifi.ondigitalocean.app";

export const APP_LOCAL_STORAGE_KEY = "BOON_V2_LOCAL_STORAGE_KEY";
export const APP_UPDATED_PRIVACY = "UPDATED_PRIVACY_ACCEPTED";
export const LOCAL_STORAGE_TOKEN_KEY = "8f3a9c2e7d1b";

export const TELEGRAM_BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME || "";

export const MSClarityProjectId = "q8twovgcex";

export const QUERY_KEYS = {
  JOINED_MISSIONS: "joinedMissions",
  USER_MISSION: "userMission",
  CREATED_COMMUNITIES: "createdCommunities",
  COMMUNITY: "community",
  CURRENT_USER: "currentUser",
  FEATURED_MISSIONS: "featuredMissions",
  MISSION: "mission",
  DISCOVERY: "DISCOVERY",
  MISSION_STATS: "missionStats",
  ALL_MISSION_STATS: "allMissionStats",
  ALL_USERS: "allUser",
  TWITTER_OAUTH: "twitterOauth",
  USER_REWARDS: "userRewards",
  VETTED_MISSIONS: "vettedMissions",
};

export const nonNavbarUrls = [
  "/",
  "/community/management/",
  "/user/management",
];

export const taskTypes = [
  {
    display: "X like",
    value: "X like",
    pattern: /https?:\/\/(www\.)?x\.com\/.+\/status\/\d+/,
    connection: true,
  },
  {
    display: "X bookmark",
    value: "X bookmark",
    pattern: /https?:\/\/(www\.)?x\.com\/.+\/status\/\d+/,
    connection: true,
  },
  {
    display: "X tweet",
    value: "X tweet",
    pattern: /.*/,
    connection: true,
  },
  {
    display: "X quote tweet",
    value: "X quote tweet",
    pattern: /https?:\/\/(www\.)?x\.com\/.+\/status\/\d+/,
    connection: true,
  },
  {
    display: "X retweet",
    value: "X retweet",
    pattern: /https?:\/\/(www\.)?x\.com\/.+\/status\/\d+/,
    connection: true,
  },
  {
    display: "X comment",
    value: "X comment",
    pattern: /https?:\/\/(www\.)?x\.com\/.+\/status\/\d+/,
    connection: true,
  },
  {
    display: "X follow",
    value: "X follow",
    pattern: /https?:\/\/(www\.)?x\.com\/.+/,
    connection: true,
  },
  {
    display: "Watch Youtube",
    value: "Watch Youtube",
    pattern: /https?:\/\/(www\.)?youtube\.com\/watch\?v=\w+/,
  },
  {
    display: "Youtube Subscribe",
    value: "Youtube Subscribe",
    pattern:
      /^(https?:\/\/)?(www\.)?youtube\.com\/(@[\w-]+|(channel|user|c)\/[A-Za-z0-9_-]+)/,
  },
  {
    display: "Share name and email address",
    value: "Share name and email address",
    pattern: /.*/,
  },
  {
    display: "Request User Info",
    value: "Request User Info",
    pattern: /.*/,
  },
  {
    display: "Visit The Link",
    value: "Visit The Link",
    pattern: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
  },
  {
    display: "Join telegram channel",
    value: "Join telegram channel",
    pattern: /https?:\/\/(www\.)?t\.me\/[a-zA-Z0-9_]+/,
    telegramConnection: true,
  },
  {
    display: "FB like",
    value: "FB like",
    pattern: /https?:\/\/(www\.)?facebook\.com\/.*/,
  },
  {
    display: "FB comment",
    value: "FB comment",
    pattern: /https?:\/\/(www\.)?facebook\.com\/.*/,
  },
  {
    display: "FB share",
    value: "FB share",
    pattern: /https?:\/\/(www\.)?facebook\.com\/.*/,
  },
  {
    display: "IG follow",
    value: "IG follow",
    pattern: /https?:\/\/(www\.)?instagram\.com\/.*/,
  },
  {
    display: "IG like",
    value: "IG like",
    pattern: /https?:\/\/(www\.)?instagram\.com\/.*/,
  },
  {
    display: "IG comment",
    value: "IG comment",
    pattern: /https?:\/\/(www\.)?instagram\.com\/.*/,
  },
  {
    display: "IG share",
    value: "IG share",
    pattern: /https?:\/\/(www\.)?instagram\.com\/.*/,
  },
  {
    display: "TikTok follow",
    value: "TikTok follow",
    pattern: /https?:\/\/(www\.)?tiktok\.com\/.*/,
  },
  {
    display: "TikTok like",
    value: "TikTok like",
    pattern: /https?:\/\/(www\.)?tiktok\.com\/.*/,
  },
  {
    display: "TikTok comment",
    value: "TikTok comment",
    pattern: /https?:\/\/(www\.)?tiktok\.com\/.*/,
  },
  {
    display: "TikTok bookmark",
    value: "TikTok bookmark",
    pattern: /https?:\/\/(www\.)?tiktok\.com\/.*/,
  },
  {
    display: "FB like, FB comment, FB share",
    value: "FB like, FB comment, FB share",
    pattern: /https?:\/\/(www\.)?facebook\.com\/.+\/posts\/\d+/,
    comingSoon: true,
  },
  {
    display: "IG follow, IG share, IG like, IG comment",
    value: "IG follow, IG share, IG like, IG comment",
    pattern: /https?:\/\/(www\.)?instagram\.com\/.+/,
    comingSoon: true,
  },
  {
    display: "Tiktok follow, like, comment, bookmark",
    value: "Tiktok follow, like, comment, bookmark",
    pattern: /https?:\/\/(www\.)?tiktok\.com\/@.+\/video\/\d+/,
    comingSoon: true,
  },
  {
    display: "Discord join, Discord post",
    value: "Discord join, Discord post",
    pattern: /https?:\/\/(www\.)?discord\.com\/invite\/.+/,
    comingSoon: true,
  },
];

export const missionTags = [
  { display: "web3", value: "web3" },
  { display: "youtubers", value: "youtubers" },
  { display: "creators", value: "creators" },

  { display: "celebrities", value: "celebrities" },
  { display: "music", value: "music" },
  { display: "brands", value: "brands" },
  { display: "travel & tourism", value: "travel-tourism" },
  { display: "sports", value: "sports" },
];

export const rewardTypes = [
  {
    display: "Digital Collectible",
    value: "Digital Collectible",
    tooltip:
      "A unique or limited-edition virtual item.  Examples include: Visual digital art, PFP collections, Token gated access or Trading cards. In the future we expect Boon to implement non-visual collectables like digital on-chain music.",
  },
  {
    display: "Digital Gift",
    value: "Digital Gift",
    tooltip:
      "Incentives that are delivered digitally to users that can be redeemed. Examples could be Gift cards, Digital vouchers, Online credit, Loyalty program points, Web3 tokens, In-game credits, Personalised messages, Access to the Booniverse or other exclusive perks. ",
  },
  {
    display: "Offline Gift",
    value: "Offline Gift",
    tooltip:
      "Tangible products that are either fulfilled by mission creators in person or an affiliated company ie. Shopify. Examples of rewards could be Branded merchandise, Signed memorabilia,  Physical tickets, or even a beverage at an in person venue",
  },
];

export const rewardFulfilmentOptions = [
  {
    display: "In-venue",
    value: "In-venue",
  },
  {
    display: "1st party",
    value: "1st party",
  },
  {
    display: "3rd party",
    value: "3rd party",
  },
];
