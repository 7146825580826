import { useEffect, useState } from "react";
import Login from "./Login";
import Otp from "./Otp";
import Register from "./Register";
import BoonModal from "../Modal";
import { useScreenSize } from "../../hooks";
import { CloseIcon } from "../../assets/icons";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const Authentication = ({
  authStrategy = "signin",
  onClose,
  modal = true,
}: {
  authStrategy?: string;
  onClose: () => void;
  modal?: boolean;
}) => {
  const { isMobile } = useScreenSize();
  const [strategy, setAuthStrategy] = useState<string>(authStrategy);

  useEffect(() => {
    setAuthStrategy(authStrategy);
  }, [authStrategy]);

  return (
    <div className="w-full h-full">
      {isMobile && !modal && (
        <button className="absolute top-4 right-4" onClick={onClose}>
          <CloseIcon className="w-8 h-8" />
        </button>
      )}
      {strategy === "signin" && (
        <Login
          setAuthStrategy={setAuthStrategy}
          completionCallback={onClose}
          modal={modal}
        />
      )}

      {strategy === "forgot_password" && (
        <ForgotPassword
          setAuthStrategy={setAuthStrategy}
          completionCallback={onClose}
          modal={modal}
        />
      )}

      {strategy === 'reset_password' && (
        <ResetPassword 
          setAuthStrategy={setAuthStrategy}
          completionCallback={onClose}
          modal={modal}
        />
      )}

      {strategy === "signup" && (
        <Register
          setAuthStrategy={setAuthStrategy}
          completionCallback={() => {
            setAuthStrategy("otp");
          }}
          modal={modal}
        />
      )}

      {strategy === "otp" && <Otp completionCallback={onClose} />}
    </div>
  );
};

const AuthenticationWrapper = ({
  modal = true,
  ...props
}: {
  authStrategy?: string;
  onClose: () => void;
  modal?: boolean;
}) => {
  const { isMobile } = useScreenSize();

  if (modal) {
    return (
      <BoonModal onClose={props.onClose} fullscreen={isMobile}>
        <Authentication {...props} modal={modal} />
      </BoonModal>
    );
  }

  return <Authentication {...props} />;
};

export default AuthenticationWrapper;
