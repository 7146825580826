import useLaunchVettedMission from "../../hooks/useMissions/useLaunchVettedMission";
import useVettedMissions from "../../hooks/useMissions/useVettedMissions";
import { Loader } from "../../components";
import MissionCard from "../../components/CommunityPreviewCard/MissionCard";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../../hooks";
import BoonModal from "../../components/Modal";
import Button, { ButtonType } from "../../components/Button";
import { isDateExpired } from "../../utils/date";

const VettedMissions = () => {
  const { data, isLoading, error } = useVettedMissions();
  const { mutate: launchVettedMission, isPending: launchVettedMissionPending } = useLaunchVettedMission();
  const [launchingMissionId, setLaunchingMissionId] = useState<string | null>(null);
  const [missionToLaunch, setMissionToLaunch] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { user } = useAuthContext();
  
  const isAdmin = user?.role === "admin";

  const handleLaunchButtonClick = (missionId: string) => {
    setMissionToLaunch(missionId);
    setShowConfirmModal(true);
  };

  const handleConfirmLaunch = () => {
    if (!missionToLaunch) return;
    
    setShowConfirmModal(false);
    setLaunchingMissionId(missionToLaunch);
    
    launchVettedMission(missionToLaunch, {
      onSuccess: () => {
        setLaunchingMissionId(null);
        toast.success("Mission launched successfully!");
      },
      onError: () => {
        setLaunchingMissionId(null);
        toast.error("Failed to launch mission. Please try again.");
      }
    });
  };

  const missions = Array.isArray(data?.missions) ? data.missions : [];

  return (
    <div className="w-full flex flex-col h-full px-14 py-8 overflow-x-hidden mmd:px-4 mmd:py-4">
      <div className="bg-surface-50 rounded-xl px-7 mmd:px-1 py-11 flex flex-col gap-16">
        <div className="text-center w-full max-w-xl m-auto flex flex-col gap-3">
          <h3 className="heading-md-medium capitalize">Vetted Missions</h3>
          <p className="body-lg first-letter:uppercase">
            All vetted missions will appear here. You can launch a mission by clicking the launch button.
          </p>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader size="lg" />
          </div>
        ) : error ? (
          <div className="text-center w-full flex flex-1 justify-center items-center text-surface-500">
            <p className="text-heading-xl-bold">Error loading missions</p>
          </div>
        ) : !missions.length ? (
          <div className="text-center w-full h-64 flex flex-1 justify-center items-center text-surface-500">
            <p className="text-heading-xl-bold">No Vetted Missions Present Yet</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7">
            {missions
              .filter((mission: any) => !isDateExpired(mission.validTo, user?.timezone?.value))
              .map((mission: any) => (
                <MissionCard
                  key={mission._id}
                  _id={mission._id}
                  title={mission.name}
                  description={mission.description}
                  tasks={mission.tasks}
                  rewards={mission.rewards}
                  imageUrl={mission.imageUrl}
                  tags={mission.tags}
                  isOverSubscribed={mission.overSubscribed}
                  validTo={mission.validTo}
                  showLaunchButton={isAdmin}
                  onLaunch={handleLaunchButtonClick}
                  isLaunching={launchVettedMissionPending && launchingMissionId === mission._id}
                />
              ))}
          </div>
        )}
      </div>

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <BoonModal
          onClose={() => setShowConfirmModal(false)}
          label="Launch Mission"
          children={<div className="flex flex-col gap-4">
            <p className="body-md-medium text-surface-900">Are you sure you want to launch this mission? This action cannot be undone.</p>
            <div className="flex gap-4 justify-center">
              <Button category={ButtonType.SECONDARY} action={() => setShowConfirmModal(false)}>Cancel</Button>
              <Button category={ButtonType.PRIMARY} action={handleConfirmLaunch}>Confirm Launch</Button>
            </div>
          </div>}
        />
      )}
    </div>
  );
};

export default VettedMissions;
