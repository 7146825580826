import _ from "lodash";
import ArrowDown from "../../assets/icons/arrowDown";
import { useAuthContext } from "../../hooks";
import Button, { ButtonType } from "../Button";
import Popover from "../Popover";
import { Link, useNavigate } from "react-router-dom";
import CreateCommunityModal from "../CreateCommunityModal";
import { useState } from "react";
import useManagedCommunities from "../../hooks/useManagedCommunities.ts";

const CommunityDropdownCard = ({
  name,
  communityId,
  url,
}: {
  name: string;
  communityId: string;
  url: string;
}) => {
  return (
    <Link to={url ? `/${url}` : `/community/${communityId}`}>
      <p className="text-body-md font-medium hover:underline px-2 cursor-pointer">
        Comm: {name}
      </p>
    </Link>
  );
};

const DropdownContent = ({
  setShowCreateCommunityModal,
}: {
  setShowCreateCommunityModal: any;
}) => {
  const { signOut } = useAuthContext();
  const navigate = useNavigate();

  const { data: userCommunities } = useManagedCommunities();

  return (
    <div className="flex flex-col gap-1 rounded-md p-2 ease-in-out px-6 justify-start items-start mmd:mr-2">
      <p
        className="text-body-md font-medium hover:underline px-2 cursor-pointer"
        onClick={() => setShowCreateCommunityModal(true)}
      >
        Create community
      </p>
      {userCommunities?.map((community) => (
        <CommunityDropdownCard
          key={community._id}
          name={community.name}
          communityId={community._id}
          url={community.url}
        />
      ))}
      <p
        className="text-body-md font-medium hover:underline px-2 cursor-pointer"
        onClick={() => navigate("/user/management?tab=Profile")}
      >
        Profile
      </p>
      <p
        className="text-body-md font-medium hover:underline px-2 cursor-pointer"
        onClick={() => navigate("/user/management?tab=My Items")}
      >
        My Items
      </p>
      <p
        className="text-body-md font-medium hover:underline px-2 cursor-pointer"
        onClick={() => navigate("/contact")}
      >
        Contact Us
      </p>
      <p
        className="text-body-md font-medium hover:underline px-2 cursor-pointer"
        onClick={signOut}
      >
        Log Out
      </p>
    </div>
  );
};

const UserDropdown = ({ disabled = false }: { disabled?: boolean }) => {
  const { user } = useAuthContext();
  const [showCreateCommunityModal, setShowCreateCommunityModal] =
    useState(false);

  return (
    <>
      {showCreateCommunityModal && (
        <CreateCommunityModal
          onClose={() => {
            setShowCreateCommunityModal(false);
          }}
        />
      )}
      <Popover
        content={
          <DropdownContent
            setShowCreateCommunityModal={setShowCreateCommunityModal}
          />
        }
      >
        <Button
          category={ButtonType.SECONDARY}
          className="flex items-center gap-3 justify-center"
          disabled={disabled}
        >
          <img
            src={user?.profilePicture} 
            className="border w-7 h-7 rounded-full object-cover bg-white"
            referrerPolicy="no-referrer"
            alt="profile_avatar"
          />
          <p className="text-body-md font-medium mr-3 mmd:hidden">
            {_.capitalize(user?.name)} {_.capitalize(user?.surname)}
          </p>
          <ArrowDown />
        </Button>
      </Popover>
    </>
  );
};

export default UserDropdown;
