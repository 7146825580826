import NavbarItem from "./NavbarItem";
import Button from "../Button";
import { useAuthContext } from "../../hooks";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/images/logo_new.png";
import UserLoggedIn from "./UserLoggedIn";
import Authentication from "../Authentication";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";
import { useEffect } from "react";

const UserLoggedOut = ({
  isValidating,
  onLogin,
}: {
  isValidating?: boolean;
  onLogin: (strategy: "signin" | "signup") => void;
}) => {
  return (
    <div className="flex items-center gap-4">
      <NavbarItem
        text="Log in"
        onClick={() => onLogin("signin")}
        classNames={["font-medium"]}
        disabled={isValidating}
      />
      <Button action={() => onLogin("signup")} progress={isValidating}>
        Create Account
      </Button>
    </div>
  );
};

const Navbar = ({ transparent = false }: { transparent?: boolean }) => {
  const { activeAuth, setActiveAuth } = useUserManagementContext();
  const { pathname } = useLocation();
  const { user, isValidating } = useAuthContext();
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const activeModal = searchParams.get('authModal');

    if(activeModal === 'reset_password') {
      setActiveAuth(activeModal);
    }
  }, [])


  return (
    <div
      className={`navbar relative items-center flex justify-between px-10 mmd:px-4 py-8 mmd:py-4 ${
        transparent ? "bg-transparent" : "bg-surface-50"
      }`}
    >
      {activeAuth && (
        <Authentication
          authStrategy={activeAuth}
          onClose={() => setActiveAuth(null)}
        />
      )}

      <div
        className="cursor-pointer w-[140px] h-[50px] mmd:w-[70px] mmd:h-[25px] relative"
        onClick={() => {
          navigate("/discovery");
        }}
      >
        <img src={Logo} alt="logo" className="w-full h-full object-contain" />
      </div>
      {!isValidating && (
        <>
          <div className="flex justify-between items-center mmd:hidden">
            <NavbarItem
              text={user ? "Dashboard" : "Home"}
              onClick={() => navigate("/dashboard")}
              disabled={isValidating}
              chosen={pathname === "/dashboard"}
            />
            <NavbarItem
              classNames={["ml-5"]}
              text={"Discovery"}
              onClick={() => navigate("/discovery")}
              disabled={isValidating}
              chosen={pathname === "/discovery"}
            />
            {user?.role === "admin" ? (
              <NavbarItem
                classNames={["ml-5"]}
                text="Users"
                onClick={() => navigate("/user_admin")}
                chosen={pathname === "/user_admin"}
              />
            ) : (
              ""
            )}
            {user?.role === "admin" ? (
              <NavbarItem
                classNames={["ml-5"]}
                text="Missions"
                onClick={() => navigate("/vetted_missions")}
                chosen={pathname === "/vetted_missions"}
              />
            ) : (
              ""
            )}
          </div>
          {user ? (
            <UserLoggedIn />
          ) : (
            <UserLoggedOut onLogin={(v) => setActiveAuth(v)} />
          )}
        </>
      )}
    </div>
  );
};

export default Navbar;
