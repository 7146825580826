import { useForm } from "react-hook-form";
import AuthHeader from "./AuthHeader";
import { TextInput } from "../TextInput";
import Button from "../Button";
import AuthFooter from "./AuthFooter";
import { Loader } from "../Loader";
import useForgotPassword from "../../hooks/useUserPassword/useForgotPassword";
import { useEffect } from "react";

const ForgotPassword = ({
  completionCallback,
  setAuthStrategy,
  modal,
}: {
  completionCallback?: () => void;
  setAuthStrategy: (strategy: string) => void;
  modal: boolean;
}) => {
  const { mutate: forgotPassword, isPending, isSuccess } = useForgotPassword()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    forgotPassword(data)
  };

  useEffect(() => {
    if(isSuccess) {
      completionCallback?.()
    }
  }, [isSuccess, completionCallback])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
    <div className="p-10 mmd:p-0 mmd:py-4">
      <AuthHeader
        title="Forgot Your Password?"
        subtitle="Enter your email to receive a reset link"
      />
      <div className="flex flex-col mt-6">
        <div className="flex flex-col">
          <TextInput
            type="text"
            name="email"
            label="Email Address"
            placeholder="Enter your email"
            registerInForm={register}
            validation={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email address",
              },
            }}
            errorMessage={errors?.email?.message as string}
          />
        </div>
        <div className="flex flex-col gap-y-[18px] text-center my-6">
          {isPending ? (
            <div className="flex justify-center items-center">
              <Loader size="md" />
            </div>
          ) : (
            <Button type="submit">Send Reset Link</Button>
          )}          
        </div>
        <AuthFooter
          actionText="Remembered your password?"
          linkText="Log in"
          setAuthStrategy={() => setAuthStrategy?.("login")}
        />
      </div>
    </div>
  </form>
  )  
};

export default ForgotPassword;