import { useState } from "react";
import ArrowDown from "../../assets/icons/arrowDown";
import classNames from "classnames";
import { useHorizontalScrollListener, useScreenSize } from "../../hooks";
import { ControlArrows } from "../ControlArrows";

export const Section = ({
  children,
  title,
  renderOpen = true,
  scrollAmount = 310,
  render = true,
  className,
  contentClassName,
}: {
  children: React.ReactNode;
  title: string;
  renderOpen?: boolean;
  scrollAmount?: number;
  render?: boolean;
  className?: string;
  contentClassName?: string;
}) => {
  const { isMobile } = useScreenSize();
  const [isExpanded, setIsExpanded] = useState(renderOpen);
  const { registerRef, canScrollLeft, canScrollRight, scroll } =
    useHorizontalScrollListener(scrollAmount);

  if (!render) return null;

  return (
    <div
      className={classNames(
        "flex flex-col mmd:border-1 mmd:border-surface-50 mmd:rounded-2xl mmd:p-4 mmd:bg-[white]",
        className,
      )}
    >
      <div
        className={classNames("flex flex-row justify-between items-center", {
          "mb-4": isExpanded,
        })}
      >
        <div className="flex flex-row items-center gap-2">
          {!isMobile && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className={classNames(
                "w-7 h-7 flex justify-center items-center rounded-full hover:bg-surface-200 transition-all duration-300 cursor-pointer",
                {
                  "rotate-180": isExpanded,
                },
              )}
            >
              <ArrowDown />
            </button>
          )}
          <h2 className="text-xl font-semibold text-surface-900 mmd:text-base">
            {title}
          </h2>
        </div>
        {isMobile && (
          <ControlArrows
            mode="dark"
            onLeftClick={() => scroll("left")}
            onRightClick={() => scroll("right")}
            leftDisabled={!canScrollLeft}
            rightDisabled={!canScrollRight}
          />
        )}
      </div>

      {isMobile ? (
        <div
          className={classNames("flex flex-row overflow-x-scroll mb-6  gap-2")}
          ref={(ref) => registerRef(ref)}
        >
          {children}
        </div>
      ) : isExpanded ? (
        <div className={classNames(!contentClassName ? "grid grid-cols-3 gap-4": contentClassName,)}>{children}</div>
      ) : null}
    </div>
  );
};
