import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { Reward } from "../useCommunity";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";
import { useErrorHandler } from "../useErrorHandler";

export const useCreateReward = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/reward/${data.missionId}/rewards`, { ...data.payload }),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.MISSION, variables.missionId],
        (oldData: any) => {
          return {
            ...oldData,
            rewards: [...oldData.rewards, data],
          };
        },
      );
    },
  });
};

export const useUpdateReward = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) =>
      put(`/api/reward/${data._id}`, { ...data.payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MISSION] });
    },
  });
};

export const useRewards = () => {
  const { get } = useAPI();

  return useQuery<{
    redeemableRewards: Reward[];
    redeemedRewards: Reward[];
  }>({
    retry: false,
    queryKey: [QUERY_KEYS.USER_REWARDS],
    queryFn: () => get(`/api/user/rewards`),
  });
};

export const useRedeemReward = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { setActiveRedeemableReward } = useContext(RewardContext);
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/reward/${data.rewardId}/redeem`, { ...data.payload }),
    onSuccess: (data) => {
      const reward = data.userReward;
      setActiveRedeemableReward({ ...reward, status: "redeemed" });
      queryClient.setQueryData([QUERY_KEYS.USER_REWARDS], (oldData: any) => {
        return {
          redeemableRewards: oldData.redeemableRewards.filter(
            (elem: Reward) => elem._id !== reward._id,
          ),
          redeemedRewards: [...oldData.redeemedRewards, reward],
        };
      });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};


export const useDeleteReward = () => {
  const { delete: Delete } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rewardId) => await Delete(`/api/reward/${rewardId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MISSION] });
    }
  })
}
