import MissionCard from "../../components/CommunityPreviewCard/MissionCard";
import { useEffect, useRef, useCallback, useState } from "react";
import useMissions from "../../hooks/useMissions";
import FilterMissions from "../../components/FilterMissions";
import { Loader } from "../../components";
import { Section } from "../../components/Dashboard";
import { FeaturedMissionCard } from "../../components/CommunityCards";
import usePrivacyContext from "../../hooks/usePrivacyContext";

const Discovery = () => {
  const { handleClickWithOptionalPrivacyCheck } = usePrivacyContext();

  const observerTarget = useRef(null);
  const [filters, setFilters] = useState({
    tags: "",
    rewards: "",
    search: "",
    by: "",
  });
  const { data: featuredMissionsData, isLoading: featuredMissionsIsLoading } =
    useMissions({ ...filters, featured: true, limit: 50 });

  const {
    data: missionsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: missionsIsLoading,
  } = useMissions(filters);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      console.log("Observer triggered:", {
        isIntersecting: target.isIntersecting,
        hasNextPage,
        isFetchingNextPage,
      });
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        console.log("Fetching next page");
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useEffect(() => {
    const element = observerTarget.current;
    const option = {
      threshold: 0,
      rootMargin: "200px 0px",
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [handleObserver]);

  const allMissions =
    missionsData?.pages.flatMap((page) => page.missions) || [];

  const featuredMissions =
    featuredMissionsData?.pages.flatMap((page) => page.missions) || [];

  return (
    <div className="w-full flex flex-col h-full px-14 py-8 overflow-x-hidden mmd:px-4 mmd:py-4">
      <div className="bg-surface-50 rounded-xl px-7 mmd:px-1 py-11 flex flex-col gap-16">
        <div className="text-center w-full max-w-xl m-auto flex flex-col gap-3">
          <h3 className="heading-md-medium capitalize">Discover Mission</h3>
          <p className="body-lg first-letter:uppercase">
            All the missions will appear here. You can explore and start a
            mission by clicking on it.
          </p>
        </div>
        <div className="w-full flex flex-col gap-6">
          <FilterMissions filters={filters} setFilters={setFilters} />
          {missionsIsLoading || featuredMissionsIsLoading ? (
            <div className="flex justify-center items-center h-screen">
              <Loader size="lg" />
            </div>
          ) : (
            <>
              {featuredMissions && featuredMissions.length > 0 && (
                <Section
                  title="$BOON Airdrop Featured Missions"
                  className="p-4 border border-solid border-[#EBEBEB] rounded-lg bg-[#F5F5F5]"
                >
                  {featuredMissions.map((mission, index) => (
                    <FeaturedMissionCard
                      key={index}
                      onClick={() =>
                        handleClickWithOptionalPrivacyCheck(
                          `/mission/${mission?._id}`,
                        )
                      }
                      avatar={mission.imageUrl}
                      name={mission.name}
                      rewards={mission.rewards}
                      mission={mission}
                      isOverSubscribed={mission?.overSubscribed}
                      validTo={mission?.validTo}
                    />
                  ))}
                </Section>
              )}
              {!allMissions.length ? (
                <div className="text-center w-full h-screen flex flex-1 justify-center items-center text-surface-500">
                  <p className="text-heading-xl-bold">
                    No Missions Present Yet
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7">
                  {allMissions.map((mission: any) => (
                    <MissionCard
                      onClick={() =>
                        handleClickWithOptionalPrivacyCheck(
                          `/mission/${mission?._id}`,
                        )
                      }
                      _id={mission?._id}
                      key={mission?._id}
                      title={mission?.name}
                      description={mission?.description}
                      tasks={mission?.tasks}
                      rewards={mission?.rewards}
                      imageUrl={mission?.imageUrl}
                      tags={mission?.tags}
                      isOverSubscribed={mission?.overSubscribed}
                      validTo={mission?.validTo}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          <div ref={observerTarget} className="h-10" />
          {isFetchingNextPage ? (
            <div className="self-center">
              <Loader className="w-full" size="lg" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Discovery;
