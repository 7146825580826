import moment from "moment-timezone";

export const formatToLocalDateTime = (
  utcDateString: string,
  timezone?: string,
) => {
  const userTimezone = timezone || moment.tz.guess();

  return moment
    .utc(utcDateString)
    .tz(userTimezone)
    .format("MMMM D, YYYY h:mm A z");
};

export const formatToLocalDate = (
  utcDateString: string,
  timezone?: string,
  format?: string
) => {
  const userTimezone = timezone || moment.tz.guess();
  return moment
    .utc(utcDateString)
    .tz(userTimezone)
    .format(format || "MMMM D, YYYY");
};

export const convertToUTC = (date: string) => {
  return moment(date).utc().format();
};

export const convertToTimezone = (date: string, timezone?: string) => {
  return moment(date)
    .tz(timezone || moment.tz.guess())
    .format();
};

export const getUserTimezone = (): string => {
  return moment.tz.guess();
};

export const isDateExpired = (date: string, timezone?: string) => {
  return moment(date)
    .tz(timezone || moment.tz.guess())
    .isBefore(moment());
};