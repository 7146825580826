import { useAuthContext } from "../../hooks/useAuthContext";
import { Mission, Reward } from "../../hooks/useCommunity";
import { isDateExpired } from "../../utils";
import Button from "../Button";
import { Ribbon } from "../Ribbon";
import ScoreCard from "./ScoreCard";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface FeaturedMissionCardProps {
  avatar: string;
  name: string;
  rewards: Reward[];
  mission: Mission;
  onClick?: () => void;
  isOverSubscribed?: boolean;
  validTo?: string;
}

export const FeaturedMissionCard = ({
  avatar,
  name,
  rewards,
  mission,
  onClick,
  isOverSubscribed,
  validTo,
}: FeaturedMissionCardProps) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const isExpired = validTo
    ? isDateExpired(validTo, user?.timezone?.value)
    : false;

  const hasUserCompletedMission = user?.joinedMissions.find(
    (m) => (m.mission as unknown as string) === mission._id && m.completedAt
  );
  return (
    <div
      className="rounded-lg border relative p-4 gap-4 bg-surface-50 border-solid border-[#EBEBEB] min-w[400px] mmd:min-w-[300px] cursor-pointer hover:shadow-md transition-all duration-300"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        if (isExpired) {
          toast.custom((t) => (
            <div className="bg-surface-900 text-surface-50 p-4 rounded-lg">
              <p className="body-sm-medium">This mission has already ended</p>
            </div>
          ));
        } else {
          navigate(`/mission/${mission._id}`);
        }
      }}
    >
      {hasUserCompletedMission ? (
        <Ribbon text="COMPLETED" />
      ) : isOverSubscribed ? (
        <Ribbon text="OVERSUBSCRIBED" />
      ) : isExpired ? (
        <Ribbon text="ENDED" />
      ) : null}
      <img
        src={avatar}
        alt="avatar"
        className="w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex gap-2 flex-col mt-2">
        <div className="flex flex-row justify-between items-center pt-2">
          <p className="body-xl-bold text-surface-900 text-[#4F575E]">{name}</p>
          <Button>See More</Button>
        </div>

        <div className="w-fit flex flex-wrap gap-4">
          {rewards?.map((reward) => <ScoreCard score={reward.name} />)}
        </div>
      </div>
    </div>
  );
};
