import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { Mission } from "../useCommunity";
import useGetMissionStats from "./useGetMissionStats";
import useVettedMissions from "./useVettedMissions";
import useCompleteMissionVerification from "./useCompleteMissionVerification";
import useGetAllMissionStats from "./useGetAllMissionStats";

export interface UserMissions {
  missions: Mission[];
}

const useMissions = (filters: {
  tags?: string | string[];
  rewards?: string | string[];
  search?: string;
  by?: string;
  limit?: number;
  featured?: boolean;
}) => {
  const { post } = useAPI();

  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.DISCOVERY, filters],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await post("/api/mission/filter", {
          page: pageParam,
          limit: filters.limit ? filters.limit : 10,
          search: filters.search,
          tags: filters.tags,
          rewards: filters.rewards,
          by: filters.by,
          featured: filters.featured,
        });

        console.log({ response });
        if (
          !response ||
          typeof response !== "object" ||
          !response.currentPage
        ) {
          throw new Error("Invalid API response");
        }
        return response;
      } catch (error) {
        console.error("Error fetching missions:", error);
        throw error;
      }
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.currentPage || !lastPage.totalPages) {
        return null;
      }
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : null;
    },
    initialPageParam: 1,
  });
};

export default useMissions;
export {
  useGetMissionStats,
  useVettedMissions,
  useCompleteMissionVerification,
  useGetAllMissionStats,
};
