import { useParams } from "react-router-dom";
import { useUserMission } from "../../hooks/useUserMissions";
import {
  Community,
  MissionPageMissionCard,
  MissionPageTasks,
  RewardsWithDisclaimer,
} from "../../components/Mission";
import useMission from "../../hooks/useUserMissions/useMission";
import { useAuthContext, useRewards } from "../../hooks";
import { Loader } from "../../components";
import { useCommunity } from "../../hooks/useCommunity";
import { ToggleSwitch } from "../../components/ToggleSwitch";
import { User } from "../../types";
import useFeaturedToggleMission from "../../hooks/useMissions/useFeaturedToggleMission";
import { Ribbon } from "../../components/Ribbon";
import { isDateExpired } from "../../utils";

const Mission = ({
  data,
  isLoading,
  isFetching,
  isSuccess,
  userMission = false,
  user,
  overSubscribed,
}: {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  userMission?: boolean;
  user?: User;
  overSubscribed?: boolean;
}) => {
  const { data: communityData, isLoading: isCommunityLoading } = useCommunity({
    _id: data?.community,
  });

  const { mutate: toggleFeatured } = useFeaturedToggleMission({ missionId: data?._id });

  const { data: userRewards, isLoading: isRewardsLoading } = useRewards();

 
  const isExpired = data?.validTo
    ? isDateExpired(data?.validTo, user?.timezone?.value)
    : false;

  const hasUserCompletedMission = user?.joinedMissions.find(
    (m) => (m.mission as unknown as string) === data?._id && m.completedAt
  );

  if (isLoading || isFetching || isCommunityLoading || isRewardsLoading) {
    return <Loader size="lg" fullScreen />;
  }

  if (!isSuccess || !data) return null;

  return (
    <div className="w-full bg-surface-150 h-full flex items-center justify-center">
      <div className="w-full bg-surface-150 h-full lg:max-w-[1200px] xl:max-w-[1400px]">
        <div className="w-full flex px-10 py-5 mmd:px-4">
          <div className="flex flex-1 relative w-full p-6 gap-6 rounded-xl bg-surface-50 min-h-[85vh] mmd:gap-4 mmd:flex-col-reverse mmd:p-2">
          {hasUserCompletedMission ? (
              <Ribbon text="COMPLETED" />
            ) : overSubscribed ? (
              <Ribbon text="OVERSUBSCRIBED" />
            ) : isExpired ? (
              <Ribbon text="ENDED" />
            ) : null}
            <div className="flex flex-[2] flex-col gap-4">
              <MissionPageMissionCard {...data} />
              <MissionPageTasks {...data} />
              {user?.role === "admin" && (
                <div className="flex gap-2">
                  <p className="body-lg text-surface-900">Featured</p>
                  <ToggleSwitch
                    name="isFeatured"
                    checked={data?.featured || false}
                    setValue={(name, e) => {
                      toggleFeatured(e);
                    }}
                  />
                </div>
              )}
            </div>
            <RewardsWithDisclaimerWrapper
              mission={data}
              userRewards={userRewards}
              className="mmd:block hidden"
            />
            <div className="flex flex-[1] flex-col gap-6 items-start">
              <Community
                id={(data as unknown as { community: string }).community}
                community={communityData}
              />
              <RewardsWithDisclaimerWrapper
                mission={data}
                userRewards={userRewards}
                className="mmd:hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RewardsWithDisclaimerForCompletedMission = ({
  rewards,
  className,
}: {
  rewards: any;
  className?: string;
}) => {
  return (
    <RewardsWithDisclaimer
      redeemableRewards={rewards?.redeemableRewards}
      redeemedRewards={rewards?.redeemedRewards}
      className={className}
      hideButton={false}
    />
  );
};

const RewardsWithDisclaimerForPublicMission = ({
  rewards,
  className,
}: {
  rewards: any;
  className?: string;
}) => {
  return (
    <RewardsWithDisclaimer
      redeemableRewards={rewards}
      redeemedRewards={[]}
      className={className}
      hideButton={true}
    />
  );
};

const RewardsWithDisclaimerWrapper = ({
  mission,
  userRewards,
  className,
}: {
  mission: any;
  userRewards: any;
  className?: string;
}) => {
  console.log("MISSION COMPLETED: ", mission);
  console.log("REWARDS: ", userRewards);
  const redeemableRewards = userRewards?.redeemableRewards.filter(
    (reward: any) => reward.mission._id === mission._id,
  );
  const redeemedRewards = userRewards?.redeemedRewards.filter(
    (reward: any) =>
      reward.mission._id === mission._id || reward.mission === mission._id,
  );
  const userRewardsFiltered = { redeemableRewards, redeemedRewards };
  console.log("USER REWARDS FILTERED: ", userRewardsFiltered);
  return mission.completedAt ? (
    <RewardsWithDisclaimerForCompletedMission
      rewards={userRewardsFiltered}
      className={className}
    />
  ) : (
    <RewardsWithDisclaimerForPublicMission
      rewards={mission.rewards}
      className={className}
    />
  );
};

const MissionForUser = ({ user }: { user: User }) => {
  const params = useParams();

  const userMission = useUserMission(params._id || "");
  console.log("userMission:", userMission);

  const { data, isLoading, isFetching, isSuccess } = userMission;

  return (
    <Mission
      {...userMission}
      userMission={true}
      user={user}
      overSubscribed={data?.overSubscribed}
    />
  );
};

const PublicMission = () => {
  const params = useParams();
  const mission = useMission({ _id: params._id });

  return <Mission {...mission} {...mission?.data}  />;
};

const MissionWrapper = () => {
  const { user, isValidating } = useAuthContext();

  if (isValidating) return <Loader size="lg" fullScreen />;

  return user ? <MissionForUser user={user} /> : <PublicMission />;
};

export default MissionWrapper;
