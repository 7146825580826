import Turnstile  from "react-turnstile";

interface TurnstileProps {
  onVerify: (token: string) => void;
  onError?: (error: unknown) => void;
  onExpire?: () => void;
  className?: string;
}

const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY || '';

const CloudflareTurnstile = ({ onVerify, onError, onExpire, className = '' }: TurnstileProps) => {
  return (
   <Turnstile
    sitekey={TURNSTILE_SITE_KEY}
    onVerify={onVerify}
    onError={onError}
    onExpire={onExpire}
   />
  );
};

export default CloudflareTurnstile; 