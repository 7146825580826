import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { useErrorHandler } from "../useErrorHandler";

const useLaunchVettedMission = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: (missionId: string) => post(`/api/mission/${missionId}/launch`, {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VETTED_MISSIONS] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useLaunchVettedMission;