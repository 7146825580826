import {
  useLocation,
  useParams,
  Navigate,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import CommunityAnalytics from "../../components/CommunityManagement/CommunityAnalytics";
import { useState } from "react";
import CommunityMissionManager from "../../components/CommunityManagement/CommunityMissionManager";
import CommunityDetails from "../../components/CommunityManagement/CommunityDetails";
import CommunityTransactions from "../../components/CommunityManagement/CommunityTransactions";
import LeftNavbar from "../../components/LeftNavbar";
import BoxesIcon from "../../assets/icons/boxes";
import FileIcon from "../../assets/icons/file";
import TransactionsIcon from "../../assets/icons/transactions";
import AnalyticsIcon from "../../assets/icons/analytics";
import HeaderWithLeftNavbar from "../../components/Navbar/HeaderWithLeftNavbar";
import { useCommunity } from "../../hooks/useCommunity";
import CreateMissionModal from "../../components/CreateMissionModal";
import EditMission from "../../components/CommunityManagement/EditMission";
import MissionStats from "../../components/CommunityManagement/MissionStats";
import { useCommunityAnalytics } from "../../hooks/useCommunityAnalytics";
import { useAuthContext, useScreenSize } from "../../hooks";
import Navbar from "../../components/Navbar";
import Popover from "../../components/Popover";
import { ArrowDownIcon } from "../../assets/icons";

const CommunityManagement = () => {
  const { isMobile } = useScreenSize();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isValidating } = useAuthContext();
  const [showCreateMissionModal, setShowCreateMissionModal] = useState(
    () => !!location.state?.showCreateMissionModal
  );
  const { data: community, isLoading: isCommunityLoading } = useCommunity({
    _id: params._id,
  });

  const { data } = useCommunityAnalytics({ _id: params._id });

  const navbarItems = [
    {
      label: "Mission Manager",
      Icon: BoxesIcon,
      path: "mission-manager",
    },
    {
      label: "Details",
      Icon: FileIcon,
      path: "details",
    },
    {
      label: "Transactions",
      Icon: TransactionsIcon,
      path: "transactions",
    },
    {
      label: "Analytics",
      Icon: AnalyticsIcon,
      path: "analytics",
    },
  ];

  const getCurrentTab = () => {
    const path = location.pathname.split("/").pop();
    return (
      navbarItems.find((item) => item.path === path)?.label || "Mission Manager"
    );
  };

  if (
    !isCommunityLoading &&
    !isValidating &&
    community?.author._id !== user?._id
  ) {
    return (
      <div className="w-full flex h-screen bg-surface-100 justify-center items-center">
        <p className="text-title-xl text-surface-900">
          You are not authorized to access this community
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex h-screen bg-surface-100">
      {showCreateMissionModal && (
        <CreateMissionModal
          onClose={() => {
            setShowCreateMissionModal(false);
          }}
        />
      )}
      {!isMobile ? (
        <div className="min-w-60">
          <LeftNavbar
            link={community?.url}
            cardName={community?.name || "My community"}
            cardImage={community?.logoUrl || community?.coverImageUrl}
            navbarItems={navbarItems}
            activeTab={getCurrentTab()}
            setActiveTab={(label) => {
              const item = navbarItems.find((item) => item.label === label);
              if (item) {
                navigate(item.path);
              }
            }}
          />
        </div>
      ) : null}
      <div className="flex flex-col w-full max-h-screen overflow-auto">
        {isMobile ? <Navbar /> : <HeaderWithLeftNavbar />}

        {isMobile && (
          <div className="flex items-center mt-4 mr-4 self-end">
            <Popover
              className="flex items-center self-end bg-white p-2 rounded-xl border border-solid border-surface-250 gap-2"
              closeOnClick={true}
              content={<DropdownContent navbarItems={navbarItems} />}
            >
              {getCurrentTab()}
              <ArrowDownIcon />
            </Popover>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="mission-manager" replace />} />
          <Route path="mission-manager" element={<CommunityMissionManager />} />
          <Route path="mission-manager/:missionId" element={<EditMission />} />
          <Route
            path="mission-manager/:missionId/stats"
            element={<MissionStats />}
          />
          <Route
            path="details"
            element={<CommunityDetails community={community} />}
          />
          <Route
            path="analytics"
            element={<CommunityAnalytics analytics={data} />}
          />
          <Route path="transactions" element={<CommunityTransactions />} />
        </Routes>
      </div>
    </div>
  );
};

const DropdownContent = ({
  navbarItems,
}: {
  navbarItems: { label: string; path: string }[];
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col border border-solid border-surface-250 rounded-xl ease-in-out py-2 bg-surface-50">
      {navbarItems.map((item) => (
        <p
          className={`text-body-md hover:bg-surface-150 px-6 py-2 w-full cursor-pointer ${location.pathname.includes(item.path) ? "bg-primary-500 text-white" : "text-surface-900"}`}
          onClick={() => navigate(item.path)}
        >
          {item.label}
        </p>
      ))}
    </div>
  );
};

export default CommunityManagement;
