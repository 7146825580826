import BoonModal from "../Modal";

const DeleteAccountModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <BoonModal onClose={onClose}>
      <div className="max-w-sm m-auto flex flex-col gap-8">
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-title-xl font-bold">To Delete Account</h3>
          <p className="text-body-lg text-[#4F575E]">
            Please email support@boonhq.io from your account’s associated email address requesting to delete your account.
          </p>
        </div>
      </div>
    </BoonModal>
  );
};

export default DeleteAccountModal;