import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

const useVettedMissions = () => {
  const { get } = useAPI();

  const result = useQuery({
    queryKey: [QUERY_KEYS.VETTED_MISSIONS],
    queryFn: () => get(`/api/mission/vetted`),
  });

  return result;
};

export default useVettedMissions;
