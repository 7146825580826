import { useForm } from "react-hook-form";
import AuthHeader from "./AuthHeader";
import { TextInput } from "../TextInput";
import Button from "../Button";
import { Loader } from "../Loader";
import useResetPassword from "../../hooks/useUserPassword/useResetPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

const ResetPassword = ({
  completionCallback,
  setAuthStrategy,
  modal,
}: {
  completionCallback?: () => void;
  setAuthStrategy: (strategy: string) => void;
  modal: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();  

  const { mutate: resetPassword, isPending } = useResetPassword()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      toast('Passwords do not match');
      return;
    }        
    resetPassword({...data, token}, {
      onSuccess: () => {
        setTimeout(() => {
            navigate('/', {replace: true})
            setAuthStrategy('signin')
        }, 1100);
      }
    })
  };

  return (
<form onSubmit={handleSubmit(onSubmit)} className="w-full">
  <div className="p-10 mmd:p-0 mmd:py-4">
    <AuthHeader
    title="Reset Your Password"
    subtitle="Enter your new password below"
    />
    <div className="flex flex-col mt-6">
      <div className="flex flex-col">
        <TextInput
          containerClassName="mt-4"
          type="password"
          name="newPassword"
          label="New Password"
          registerInForm={register}
          validation={{
            required: "New Password is required",
          }}
          placeholder="Enter new password"
          errorMessage={errors?.newPassword?.message as string}
        />  
        <TextInput
          containerClassName="mt-4"
          type="password"
          name="confirmPassword"
          label="Confirm New Password"
          registerInForm={register}
          validation={{
            required: "Please confirm your new password",
          }}
          placeholder="Confirm new password"
          errorMessage={errors?.confirmPassword?.message as string}
        />
      </div>
      <div className="flex flex-col gap-y-[18px] text-center my-6">
        {isPending ? (
          <div className="flex justify-center items-center">
            <Loader size="md" />
          </div>
        ) : (
          <Button type="submit">Reset Password</Button>
        )}          
      </div>
    </div>
  </div>
</form>

  );
};

export default ResetPassword;