import { useMutation } from "@tanstack/react-query"
import { useAPI } from "../useApi"
import { useErrorHandler } from "../useErrorHandler";
import { useSuccessHandler } from "../useSuccessHandler"



const useResetPassword = () => {
    const { post } = useAPI()
    const { handleApiError } = useErrorHandler();
    const { showSuccessMessage } = useSuccessHandler()

    return useMutation({
        mutationFn: (data: any) => post('/api/user/reset-password', data),
        onSuccess: (data) => {
            showSuccessMessage(data.message);
        },
        onError: (error) => {
            handleApiError(error)
        }
    })
}


export default useResetPassword
