import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAPI } from "../useApi"
import { QUERY_KEYS } from "../../constants"



export const useArchiveMission = ( ) => {
    const { post } = useAPI()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (missionId) => await post(`/api/mission/${missionId}/archive`, {}),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
        }
    })
}

export const useUnarchiveMission = () => {
    const { post } = useAPI()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (missionId) => await post(`/api/mission/${missionId}/unarchive`, {}),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
        }
    })
}