import Navbar from "../../components/Navbar";

const Contact = () => {
  return (
    <div
      className="w-full h-full flex-1 flex flex-col bg-white justify-start"
      style={{
      }}
    >
      <div className="flex flex-col items-center justify-center pt-20 mb-12 mmd:pt-12 mmd:pb-2 bg-white">
        <h1 className="text-4xl font-bold text-center mb-8">Contact Us</h1>
        
        <div className="max-w-2xl w-full mx-auto px-4">
          <div className="bg-white rounded-lg p-8 space-y-6">
            <div>
              <h2 className="text-xl font-semibold mb-2">Email</h2>
              <a 
                href="mailto:hello@boonhq.io" 
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                hello@boonhq.io
              </a>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">Telegram</h2>
              <a 
                href="https://t.me/boonapp/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 transition-colors"
              >
                @boonapp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact; 