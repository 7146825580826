import { useForm } from "react-hook-form";
import Button from "../Button";
import AuthHeader from "./AuthHeader";
import { TextInput } from "../TextInput";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext, useVerifyOtp } from "../../hooks";
import { Loader } from "../Loader";
import { useResendOtp } from "../../hooks/useResendOtp";

const Otp = ({ completionCallback }: { completionCallback?: () => void }) => {
  const [otpCode, setOtpCode] = useState<string[]>(["", "", "", ""]);
  const [resendTimeout, setResendTimeout] = useState(30);
  const { register, handleSubmit } = useForm();
  const { unverifiedUserId } = useAuthContext();
  const {
    mutate: verifyOtp,
    isPending: isVerifyOtpPending,
    error: verifyOtpError,
  } = useVerifyOtp();

  const { mutate: resendOtp, isPending: isResendOtpPending } = useResendOtp();

  const onSubmit = useCallback(
    (data: any) => {
      const otp = `${data.code0}${data.code1}${data.code2}${data.code3}`;
      if (unverifiedUserId) {
        verifyOtp(
          { otp },
          {
            onSuccess: () => {
              if (completionCallback) {
                completionCallback();
              }
            },
          },
        );
      } else {
        console.log("No unverified user id");
      }
    },
    [completionCallback, unverifiedUserId, verifyOtp],
  );

  useEffect(() => {
    const result = setTimeout(() => {
      setResendTimeout((prev) => {
        if (prev === 0) {
          if (result) {
            clearTimeout(result);
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearTimeout(result);
  }, [resendTimeout]);

  const handleChange = (value: string, index: number) => {
    if (value.length <= 1) {
      const newCode = [...otpCode];
      newCode[index] = value;
      setOtpCode(newCode);

      if (value && index < 3) {
        const nextInput = document.getElementById(`code${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleRemove = (index: number) => {
    const newCode = [...otpCode];
    newCode[index] = "";
    setOtpCode(newCode);

    if (index > 0) {
      const prevInput = document.getElementById(`code${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === "Backspace" && otpCode[index] === "") {
      event.preventDefault();
      handleRemove(index);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full mmd:h-full">
      <div className="p-10 mmd:p-0 mmd:py-4 mmd:h-full mmd:flex mmd:flex-col mmd:justify-center mmd:items-center">
        <AuthHeader
          title="OTP Verification"
          subtitle="Enter the 4-digit code sent to your email"
        />
        <div className="flex flex-col mt-6">
          <div className="flex justify-between gap-4 mb-6">
            {otpCode.map((_, index) => (
              <TextInput
                key={index}
                type="text"
                id={`code${index}`}
                name={`code${index}`}
                registerInForm={register}
                inputMode="numeric"
                placeholder="-"
                onChange={(e: any) => handleChange(e.target.value, index)}
                onKeyDown={(e: any) => handleKeyDown(e, index)}
                validation={{
                  required: "Required",
                  pattern: {
                    value: /^[0-9]$/,
                    message: "Must be a single digit",
                  },
                }}
                maxLength={1}
                className="w-[54px] h-[54px] text-center text-lg border-2 border-surface-250 opacity-80 rounded-4"
              />
            ))}
          </div>
          {verifyOtpError && (
            <p className="text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out text-center">
              {verifyOtpError?.message}
            </p>
          )}
          {isVerifyOtpPending ? (
            <div className="flex justify-center items-center">
              <Loader size="md" />
            </div>
          ) : (
            <Button type="submit">Verify Code</Button>
          )}
          <div className="flex gap-3 justify-center mt-4">
            {resendTimeout !== 0 ? (
              <span className="text-body-lg font-space-grotesk">
                Resend in {resendTimeout}
              </span>
            ) : isResendOtpPending ? (
              <Loader size="sm" />
            ) : (
              <p
                className="text-[#4F575E] cursor-pointer hover:underline"
                onClick={() => {
                  if (unverifiedUserId) {
                    resendOtp();
                    setResendTimeout(30);
                  }
                }}
              >
                Resend Code
              </p>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Otp;
