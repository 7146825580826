import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { Mission, Task, TaskStatus } from "../useCommunity";
import { useErrorHandler } from "../useErrorHandler";

export const absoluteLink = (link: string) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

const updatedMission = (
  mission: Mission,
  taskId: string,
  taskStatus: TaskStatus
) => {
  const res = {
    ...mission,
    tasks: mission.tasks.map((elem: Task) => {
      return elem._id === taskId
        ? { ...elem, status: taskStatus }
        : { ...elem };
    }),
  };
  if (res.tasks.every((task: Task) => task.status === TaskStatus.COMPLETED)) {
    res.completedAt = new Date().toLocaleString();
  }
  return res;
};

const updatedMissionWithMultipleTasks = (mission: Mission, data: any) => {
  const updatedTasks = mission.tasks.map((task: Task) => {
    const update = data.results.find(
      (result: any) => result.taskId === task._id
    );
    return update ? { ...task, status: update.status } : task;
  });

  const updatedMission = {
    ...mission,
    tasks: updatedTasks,
    lastCheckTime: data.lastCheckTime,
    completedAt: data.allTasksCompleted
      ? new Date().toLocaleString()
      : mission.completedAt,
  };

  return updatedMission;
};

export const useStartTask = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/task/start/${data._id}`, { userInfo: data?.userInfo }),
    onSuccess: (data, variables) => {
      if (
        variables.type.toLowerCase() !== "share name and email address" &&
        variables.type.toLowerCase() !== "request user info"
      ) {
        window.open(
          absoluteLink(
            variables.type === "X tweet"
              ? `https://x.com/intent/post?text=${variables.link}`
              : variables.link
          ),
          "_blank",
          "noopener,noreferrer"
        );
      }
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables._id, TaskStatus.IN_PROGRESS)
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useCheckAllTasks = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();
  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/task/check/all/${data.missionId}`, {}),
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMissionWithMultipleTasks(oldData, data)
      );
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_REWARDS] });
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_MISSION] });
    },
    onError: (error, variables) => {
      handleApiError(error);
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables._id, TaskStatus.FAILED)
      );
    },
  });
};

export const useCompleteTask = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/task/complete/${data.taskId}`, {}),
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables.taskId, TaskStatus.COMPLETED)
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useStartAndCompleteTask = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) => {
      await post(`/api/task/start/${data._id}`, {});

      return post(`/api/task/complete/${data._id}`, {});
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables._id, TaskStatus.COMPLETED)
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useUploadTaskScreenshot = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: {
      missionId: string;
      taskId: string;
      proof: string;
    }) => {
      return post(`/api/task/upload-screenshot/${data.taskId}`, {
        missionId: data.missionId,
        proof: data.proof,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.USER_MISSION],
        (oldData: Mission) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            tasks: oldData.tasks.map((task) => {
              if (task._id === variables.taskId) {
                return {
                  ...task,
                  screenshotImageUrl:
                    data?.userTask?.screenshotImageUrl || variables.proof,
                };
              }
              return task;
            }),
          };
        }
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};
