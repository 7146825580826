import WrenchIcon from "../../../assets/icons/wrenchIcon";
import DeactivatedIcon from "../../../assets/icons/deactivated";
import WarningIcon from "../../../assets/icons/warningIcon";
import type { Mission } from "../../../hooks/useCommunity";

type MissionStatusTagProps = {
  mission?: Mission;
  active?: boolean;
  launched?: boolean;
  overSubscribed?: boolean;
};

const MissionStatusTag = ({
  mission,
  active,
  launched,
  overSubscribed,
}: MissionStatusTagProps) => {
  // If a mission object is provided, use its properties
  // Otherwise use the individual props
  const isLaunched = mission ? mission.launched : launched;
  const isActive = mission ? mission.active : active;
  const isOverSubscribed = mission ? mission.overSubscribed : overSubscribed;

  // Deactivated is the opposite of active
  const isDeactivated = typeof isActive !== "undefined" ? !isActive : false;

  return (
    <div>
      <div className="flex gap-2">
        {isLaunched === false && (
          <div className="flex items-center gap-1 bg-[#FFDD00]/10 rounded-md p-2">
            <WrenchIcon />
            <span className="text-[#ECC207] text-sm font-semibold">
              UNLAUNCHED
            </span>
          </div>
        )}
        {isLaunched === true && (
          <div className="flex items-center gap-1 bg-[#00A36C]/10 rounded-md p-2">
            <span className="text-[#00A36C] text-sm font-semibold">
              LAUNCHED
            </span>
          </div>
        )}
        {isDeactivated && (
          <div className="flex items-center gap-1 bg-[#C92929]/10 rounded-md p-2">
            <DeactivatedIcon />
            <span className="text-[#C92929] text-sm font-semibold">
              DEACTIVATED
            </span>
          </div>
        )}
        {isOverSubscribed && (
          <div className="flex items-center gap-1 bg-[#FFDD00]/10 rounded-md p-2">
            <WarningIcon />
            <span className="text-[#ECC207] text-sm font-semibold">
              OVERSUBSCRIBED
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionStatusTag;
