import useManagedCommunities, {
  type ManagedCommunity,
} from "../../hooks/useManagedCommunities.ts";
import { useUserMissions } from "../../hooks/useUserMissions";
import CommunityPrevCard from "../../components/CommunityCards/CommunityPrevCard";
import {
  CompletedMissionCard,
  DashboardMissionCard,
  FeaturedMissionCard,
} from "../../components/CommunityCards";
import { Rewards, Section } from "../../components/Dashboard";
import { useMemo } from "react";
import EmptyPage from "../../components/EmptyPage";
import { useAuthContext, useRewards, useUserTimezoneSync } from "../../hooks";
import { Loader } from "../../components";
import useMissions from "../../hooks/useMissions";
import usePrivacyContext from "../../hooks/usePrivacyContext";

const Dashboard = () => {
  useUserTimezoneSync();
  const { data: missions, isLoading: isMissionsLoading } = useUserMissions();

  const { data: featuredMissionsData, isLoading: featuredMissionsIsLoading } =
    useMissions({ featured: true, limit: 50 });

  const {
    data: managedCommunities = [],
    isLoading: isManagedCommunitiesLoading,
  } = useManagedCommunities();
  const { isValidating } = useAuthContext();
  const { handleClickWithOptionalPrivacyCheck } = usePrivacyContext();

  const { data: rewards, isLoading: isRewardsLoading } = useRewards();

  const isLoading =
    isMissionsLoading ||
    isManagedCommunitiesLoading ||
    featuredMissionsIsLoading ||
    isRewardsLoading ||
    isValidating;

  const { ongoingMissions, completedMissions } = useMemo(() => {
    const ongoingMissions =
      missions?.missions.filter((mission) => !mission.completedAt) ?? [];
    const completedMissions =
      missions?.missions.filter((mission) => mission.completedAt) ?? [];

    return {
      ongoingMissions,
      completedMissions,
    };
  }, [missions]);

  if (isLoading) {
    return (
      <div className="bg-surface-100 flex h-screen justify-center items-center">
        <Loader size="lg" />
      </div>
    );
  }

  const featuredMissions =
    featuredMissionsData?.pages.flatMap((page) => page.missions) || [];

  return (
    <div className="bg-[#F5F5F5] flex-1 md:px-12">
      <div className="w-full p-12 mmd:p-6 flex flex-col gap-6 gap-y-12">
        <Rewards
          data={{
            redeemableRewards: rewards?.redeemableRewards || [],
            redeemedRewards: [],
          }}
        />
        {ongoingMissions && ongoingMissions.length ? (
          <Section title="My ongoing missions">
            {ongoingMissions.map((mission) => (
              <DashboardMissionCard
                onClick={() =>
                  handleClickWithOptionalPrivacyCheck(`/mission/${mission._id}`)
                }
                key={mission._id}
                {...mission}
                _id={mission._id}
                communityId={mission.community._id}
                nextTaskName={mission.nextTask?.type || ""}
                communityName={mission.community?.name}
                missionName={mission.name}
                tasksFinished={mission.completedTasks?.length || 0}
                tasksTotal={mission.tasks?.length || 0}
                isOverSubscribed={mission.overSubscribed}
                validTo={mission.validTo}
              />
            ))}
          </Section>
        ) : null}

        <Section
          title="My managed communities"
          render={!!managedCommunities?.length}
        >
          {managedCommunities.map((community: ManagedCommunity) => (
            <CommunityPrevCard
              onClick={() =>
                handleClickWithOptionalPrivacyCheck(
                  `/community/${community._id}`,
                )
              }
              key={community._id}
              _id={community._id}
              name={community.name}
              logoUrl={community.logoUrl}
              description={community.description}
              stats={community.stats}
              url={community.url}
            />
          ))}
        </Section>

        {!ongoingMissions.length &&
          !managedCommunities.length &&
          !completedMissions.length && (
            <div className="mt-12">
              <EmptyPage />
            </div>
          )}

        <Section
          title="$BOON Airdrop Featured Missions"
          render={!!featuredMissions?.length}
          className="p-4 border border-solid border-[#EBEBEB] rounded-lg"
        >
          {featuredMissions?.map((mission, index) => (
            <FeaturedMissionCard
              onClick={() =>
                handleClickWithOptionalPrivacyCheck(`/mission/${mission._id}`)
              }
              key={index}
              avatar={mission.imageUrl}
              name={mission.name}
              rewards={mission.rewards}
              mission={mission}
              isOverSubscribed={mission.overSubscribed}
              validTo={mission.validTo}
            />
          ))}
        </Section>

        <Section
          title="Completed missions"
          scrollAmount={280}
          render={!!completedMissions.length}
          renderOpen={false}
        >
          {completedMissions.map((mission) => (
            <CompletedMissionCard
              key={mission._id}
              missionId={mission._id}
              communityId={mission.community._id}
              community={mission.community.name}
              rewards={mission.rewards}
              name={mission.name}
              redeemed={false} //TODO: change this once rewards redemption flow is implemented
            />
          ))}
        </Section>
      </div>
    </div>
  );
};

export default Dashboard;
