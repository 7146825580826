import { useForm } from "react-hook-form";
import BoonModal from "../Modal";
import { TextInput } from "../TextInput";
import Button from "../Button";

const UserInfoInputModal = ({
  onClose,
  onSubmit,
  task,
}: {
  onClose: () => void;
  onSubmit: (data: any) => void;
  task: any;
}) => {
    const {
        register,
        handleSubmit,
    } = useForm();

    const onInputSubmit = (data: any) => {
        onSubmit({userInfo: data});
        onClose();
    }

  return (
    <BoonModal onClose={onClose} label={task.type}>
        <form onSubmit={handleSubmit(onInputSubmit)} className="w-full">
            <TextInput
                label={task.link}
                placeholder="Enter your information"
                registerInForm={register}
                name="userInfo"
            />
            <Button type="submit" className="w-full my-4">Submit</Button>
        </form>
    </BoonModal>
  );
};

export default UserInfoInputModal;
