import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

interface StatusUpdate {
  userId: string;
  status: string;
}

/**
 * Hook for completing verification of mission tasks.
 * This updates mission and task statuses with the provided status values.
 */
const useCompleteMissionVerification = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      missionId,
      statusUpdates,
    }: {
      missionId: string;
      statusUpdates: StatusUpdate[];
    }) => {
      return post(`/api/mission/${missionId}/update-statuses`, {
        statusUpdates,
      });
    },
    onSuccess: (_, variables) => {
      // Invalidate the mission stats query to refresh the data
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MISSION_STATS, variables.missionId],
      });
    },
  });
};

export default useCompleteMissionVerification;
