import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

/**
 * Hook to fetch all mission stats for a mission with no pagination limit
 * Used for exporting all user data
 */
const useGetAllMissionStats = ({
  _id,
  mockData = false,
}: {
  _id: string;
  mockData?: boolean;
}) => {
  const { get } = useAPI();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.ALL_MISSION_STATS, _id, mockData],
    queryFn: () => {
      if (!_id) return null;
      // Set a very high limit to get all users at once
      // The backend should handle this properly
      return get(
        `/api/mission/${_id}/stats?page=1&limit=10000&mockData=${mockData}`
      );
    },
    enabled: !!_id,
    // Disable caching for this query to avoid storing large datasets
    gcTime: 0,
    staleTime: 0,
  });

  return {
    data,
    isLoading,
  };
};

export default useGetAllMissionStats;
