import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

const useGetMissionStats = ({
  _id,
  page = 1,
  limit = 10,
  mockData = false,
}: {
  _id: string;
  page?: number;
  limit?: number;
  mockData?: boolean;
}) => {
  const { get } = useAPI();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.MISSION_STATS, _id, page, limit, mockData],
    queryFn: () => {
      if (!_id) return null;
      return get(
        `/api/mission/${_id}/stats?page=${page}&limit=${limit}&mockData=${mockData}`
      );
    },
    enabled: !!_id,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useGetMissionStats;
