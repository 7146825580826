import { TailSpin } from "react-loader-spinner";
import Button from "../Button";
import BoonModal from "../Modal";
import PreviewBox from "../CreateMission/PreviewBox";

const LaunchedModal = ({
  onClose,
  onSubmit,
  isPending,
  mission,
}: {
  onClose: () => void;
  onSubmit: () => void;
  isPending: boolean;
  mission: any;
}) => {
  return (
    <BoonModal
    onClose={onClose}
    style={{
      background: "transparent",
      border: "none",
      padding: "0",
    }}
    width="980px"
    options={{
      mobileHeight: "90vh",
    }}
  >
      <div className="flex w-full flex-col gap-3 items-center md:flex-row md:items-start">
        <div className="max-w-xl p-8 rounded-2xl m-auto bg-white  flex flex-col gap-8">
          <div className="flex flex-col gap-3 text-center">
            <h3 className="text-title-xl font-bold">Warning</h3>
            <p className="text-body-lg text-[#4F575E]">
              Once launched you won’t be able to edit any mission details.
            </p>
          </div>
          <div className="flex flex-col w-full gap-4">
            {isPending ? (
              <TailSpin width={40} wrapperStyle={{ margin: "auto" }} />
            ) : (
              <>
                <Button action={onSubmit} className="!bg-[#C92929] py-2">
                  Yes, launch & lock mission
                </Button>
                <Button
                  action={onClose}
                  className="!bg-[#FFFFFF] py-2 !text-black border border-solid border-[#EBEBEB]"
                >
                  cancel
                </Button>
              </>
            )}
          </div>
        </div>
        <PreviewBox values={mission} />
      </div>
    </BoonModal>
  );
};

export default LaunchedModal;
